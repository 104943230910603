import classNames from "classnames/bind";
import styles from "./sidebar.module.css";
import { ImHome } from "react-icons/im";

import SidebarData from "./sidebar-data/SidebarData";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../utils/context/ThemeProvider";

const cx = classNames.bind(styles);
function Sidebar() {
  const listName = [
    {
      id: 0,
      name: "Player Management",
      isActive: false,
      children: [
        {
          name: "List Players",
          destination: "listPlayers",
        },
      ],
    },
    {
      id: 1,
      name: "Game Modes Management",
      isActive: false,
      children: [
        {
          name: "Create Game Mode",
          destination: "createGameMode",
        },
        {
          name: "List Game Mode",
          destination: "listGameMode",
        },
      ],
    },
    {
      id: 2,
      name: "Game Play Report",
      isActive: false,
      children: [
        {
          name: "List Game Play",
          destination: "listGamePlay",
        },
      ],
    },
    {
      id: 3,
      name: "Mini Tournament Management",
      isActive: false,
      children: [
        {
          name: "Config",
          destination: "minTournament",
        },
        {
          name: "Report",
          destination: "reportTournament",
        },
      ],
    },
    {
      id: 4,
      name: "Royal Game Management",
      isActive: false,
      children: [
        { name: "Config", destination: "configRoyalGame" },
        { name: "Report", destination: "reportRoyalGame" },
      ],
    },
  ];
  const context = useContext(ThemeContext);
  return (
    <div className={cx("container")}>
      <ul
        className={cx(
          "sidebar-container",
          `${context?.isActiveMenu ? "active" : ""}`
        )}
      >
        <Link className={cx("link")} to="/dashboard">
          <li
            className={cx("dashboard", "wrapper")}
            onClick={() => context?.handleIsActiveMenu()}
          >
            <div className={cx("sidebar-icon")}>
              <ImHome />
              <span>Dashboard</span>
            </div>
          </li>
        </Link>

        {listName.map((menu, index) => (
          <div key={index}>
            <SidebarData
              id={menu.id}
              name={menu.name}
              children={menu.children}
              index={index}
            />
          </div>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
