import React, { useEffect, useState } from 'react';
import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Config from '../../config/config';
import { useQuery } from '@tanstack/react-query';
import { getRoyalGameReport } from '../../services/RoyalGameConfig.services';
import { toast } from 'react-toastify';
import { Col, TabContainer } from 'react-bootstrap';
import "./royal.css"
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { GrView } from 'react-icons/gr';
import Loading from '../../components/loading/Loading';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
interface IRoyalGames {
    _id: string,
    name: string,
    status: string,
    startTime: number | null,
    endTime: number | null,
    minPlayers: number,
    maxPlayers: number,
    marked: boolean,
    updatedAt: string,
    createdAt: string,
    gameId: string,
    winner: any,
    noOfPlayers: number
}


const LIMIT_PAGE = Config.limitPage;
const RoyalGameReport = () => {
    const [tableData, setTableData] = useState<IRoyalGames[]>([])
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(1);
    const [sort, setSort] = useState<number>(-1);


    const totalPage = Math.ceil(total / LIMIT_PAGE);
    const handlePageChange = (e: any) => {
        const { selected } = e;
        setPage(selected);
    };
    const handleSortChange = (event: SelectChangeEvent) => {
        setSort(+event.target.value as number);
    };
    const { isLoading, refetch } = useQuery(["GET_ROYAL_REPORT", page, sort], () => getRoyalGameReport(page * LIMIT_PAGE, LIMIT_PAGE, sort),
        {
            onSuccess: (res) => {
                if (res.data.status) {
                    setTableData(res.data?.royalGames);
                    setTotal(res.data.total);
                }
                else {
                    toast.error(res?.data?.message);
                }
            },
            onError: () => {
                toast.error("Connect to server failed.");
            },
        });

    const displayData = tableData?.map(({ minPlayers, startTime, endTime, gameId, status, winner, noOfPlayers }, index: number) => {
        return (
            <tr key={index}>
                <td>{LIMIT_PAGE * page + (index + 1)}</td>
                <td>{gameId}</td>
                <td>{startTime && !isNaN(startTime) ? moment(startTime).format("DD/MM/YYYY HH:mm") : "N/A"}</td>
                <td>{endTime && !isNaN(endTime) ? moment(endTime).format("DD/MM/YYYY HH:mm") : "N/A"}</td>
                <td>{noOfPlayers}</td>
                <td>{status === "SCHEDULE_STARTED" ? "WAITING" :
                    status === "FREEZED" ? status :
                        status === "STARTED" ? "HAPPENING" :
                            (status == "SCHEDULE_STOPPED" || status == "CLOSED") ? "CLOSED (before happening)" : status}</td>
                <td>{winner?.username && status == "ENDED" ? winner?.username : "N/A"}</td>
                <td>
                    <Link to={`/reportRoyalGame/details/${gameId}`}>
                        <Button className=" action-icon" variant="outline-info">
                            <GrView />
                        </Button>
                    </Link>
                </td>
            </tr>
        );
    }
    );



    return (
        <TabContainer>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="list-tournament pb-5">
                    <Col>
                        <BreadCrumbs
                            childItem="Report"
                            parentItem="Royal Game Management"
                            parentLink="reportRoyalGame" />
                        <div className="card m-auto custom onlyforthis">
                            <div className="portlet-title">
                                <div className='title-wrapper'>
                                    <h3 className="page-title">Royal Game Report</h3>
                                </div>
                            </div>
                            <div className='mt-3 p-3 custom-sort'>
                                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                                    <InputLabel id="select-label">Sort</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        id="select-label"
                                        value={String(sort)}
                                        label="Sort by"
                                        onChange={handleSortChange}>
                                        <MenuItem id="select-label" value={-1}>Newest</MenuItem>
                                        <MenuItem id="select-label" value={1}>Oldest</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="table-data ">
                                <table className="table table-bordered mt-5">
                                    <thead>
                                        <tr className="trTournament">
                                            <th>No.</th>
                                            <th>Game ID</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>No. of Players</th>
                                            <th>Status</th>
                                            <th>Winner</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>{displayData}</tbody>
                                </table>
                            </div>
                        </div>
                    </Col>
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        forcePage={page}
                        pageCount={totalPage}
                        onPageChange={handlePageChange}
                        activeClassName={"page-item active"}
                        disabledClassName={"page-item disabled"}
                        containerClassName={"pagination"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        pageLinkClassName={"page-link"}
                    />
                </div>
            )}

        </TabContainer>
    );
}
export default RoyalGameReport;