import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import GameModeService from "../../../services/GameMode.services";
import { createGameModeSchema } from "../../../utils/schemaValidation";
import "./styles.css";
type FormData = {
  name: string;
  isRankedGame: string;
  supplyRequired: number;
  unlockLevel: number;
  isGuest: boolean;
  guessQuestionTimer: number;
  mcqQuestionTimer: number;
  noOfFreePerks: number;
  buyLimit: number;
  buyPrice: number;
  lastWinnerReward: number;
  coinReward1: number;
  coinReward2: number;
  coinReward3: number;
  coinReward: number[];
};

const defaultValue = {
  name: "",
  isRankedGame: "",
  supplyRequired: 0,
  unlockLevel: 0,
  isGuest: false,
};

const CreateGameMode = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FormData>({
    resolver: yupResolver(createGameModeSchema),
    defaultValues: defaultValue,
  });

  const getGameMode = () => {
    setLoading(true);
    GameModeService.getGameMode(id).then(
      (res: any) => {
        if (res) {
          const { name, isRankedGame, supplyRequired, unlockLevel, isGuest,
            mcqQuestionTimer, guessQuestionTimer, noOfFreePerks, buyLimit, buyPrice, lastWinnerReward,
            coinReward
          } =
            res;
          setValue("supplyRequired", supplyRequired);
          setValue("unlockLevel", unlockLevel);
          setValue("name", name);
          setValue("isRankedGame", isRankedGame);
          setValue("isGuest", isGuest);
          if (name === "Royal Game") {
            setValue("mcqQuestionTimer", mcqQuestionTimer);
            setValue("guessQuestionTimer", guessQuestionTimer);

            setValue("noOfFreePerks", noOfFreePerks);
            setValue("buyLimit", buyLimit);
            setValue("buyPrice", buyPrice);
            setValue("lastWinnerReward", lastWinnerReward);
          }
          if (name === "Friendly Game") {
            setValue("coinReward", coinReward ? coinReward : [300, 150, 50]);
            setValue("coinReward1", coinReward && !isNaN(+coinReward[0]) ? +coinReward[0] : 300);
            setValue("coinReward2", coinReward && !isNaN(+coinReward[1]) ? +coinReward[1] : 150);
            setValue("coinReward3", coinReward && !isNaN(+coinReward[2]) ? +coinReward[2] : 50);
          }
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  useEffect(() => {
    getGameMode();
  }, []);

  const isRanked = watch().isRankedGame === "Yes" ? true : false;
  const isRoyalGame = watch()?.name === "Royal Game";
  const isFriendlyGame = watch()?.name === "Friendly Game";

  const onSubmit = (data: FormData) => {
    const finalData: Partial<FormData> = data;
    if (data?.name !== "Royal Game") {
      delete finalData?.mcqQuestionTimer;
      delete finalData?.guessQuestionTimer;
      delete finalData?.buyLimit;
      delete finalData?.buyPrice;
      delete finalData?.noOfFreePerks;
      delete finalData?.lastWinnerReward;
    }
    if (data?.name !== "Friendly Game") {
      delete finalData?.coinReward;
      delete finalData?.coinReward1;
      delete finalData?.coinReward2;
      delete finalData?.coinReward3;
    } else {
      if (finalData?.coinReward1 && finalData?.coinReward2 && finalData?.coinReward3) {
        const coinReward1 = finalData?.coinReward1;
        const coinReward2 = finalData?.coinReward2;
        const coinReward3 = finalData?.coinReward3;

        if (coinReward1 < coinReward2 || coinReward1 < coinReward3 || coinReward2 < coinReward3) {
          toast.error("Coin reward is invalid. Please order from largest to smallest");
          throw new Error("Coin reward is invalid. Please order from largest to smallest");
        }
        finalData.coinReward = [coinReward1, coinReward2, coinReward3];
      }
      else {
        toast.error("Coin reward is invalid.");
        throw new Error("Coin reward is invalid.");
      }
    }
    console.log(finalData);
    setLoading(true);

    GameModeService.updateGameMode(finalData, id).then(
      (res: any) => {
        if (res._id) {
          toast.success("Update Game Mode Successfully");
          setLoading(false);
          Navigate("/listGameMode");
        }
      },
      (err) => {
        toast.error("Created Game Mode Fail");
      }
    );
  };
  useEffect(() => {
    if (!isRanked) setValue("supplyRequired", 0);
  }, [isRanked]);
  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="create-time-bank pb-5">
          <Col>
            <BreadCrumbs childItem="Edit Game Mode" parentItem="Game Modes Management" parentLink="listGameMode" />
            <div className="card m-auto text-center">
              <h3 className="page-title text-center">Edit Game Mode</h3>
              <div className="body-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="gameModeName"
                          className="form-control-label"
                        >
                          Game Mode Name
                        </label>
                        <input
                          id="gameModeName"
                          type="text"
                          className="form-control"
                          {...register("name")}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => (
                            <p className="text-danger fs-3 mt-2">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label
                          htmlFor="isRankedGame"
                          className="form-control-label"
                        >
                          Is Ranked Game
                        </label>
                        <select
                          id="isRankedGame"
                          className="form-control"
                          {...register("isRankedGame")}
                        >
                          <option value="" hidden>
                            Choose options
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name="isRankedGame"
                          render={({ message }) => (
                            <p className="text-danger fs-3 mt-2">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    {/* {isRanked && (
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="supplyRequired"
                            className="form-control-label"
                          >
                            Supplies Required
                          </label>
                          <input
                            id="supplyRequired"
                            type="number"
                            className="form-control"
                            {...register("supplyRequired", {
                              valueAsNumber: true,
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="supplyRequired"
                            render={({ message }) => (
                              <p className="text-danger fs-3 mt-2">{message}</p>
                            )}
                          />
                        </div>
                      </div>
                    )} */}
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label
                          htmlFor="supplyRequired"
                          className="form-control-label"
                        >
                          Supplies Required
                        </label>
                        <input
                          id="supplyRequired"
                          type="number"
                          className="form-control"
                          {...register("supplyRequired", {
                            valueAsNumber: true,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="supplyRequired"
                          render={({ message }) => (
                            <p className="text-danger fs-3 mt-2">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label
                          htmlFor="unlockLevel"
                          className="form-control-label"
                        >
                          Unlock Level
                        </label>
                        <input
                          id="unlockLevel"
                          type="number"
                          className="form-control"
                          {...register("unlockLevel", {
                            valueAsNumber: true,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="unlockLevel"
                          render={({ message }) => (
                            <p className="text-danger fs-3 mt-2">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label htmlFor="isGuest" className="form-control-label">
                          Is Guest
                        </label>
                        <select
                          id="isGuest"
                          className="form-control"
                          {...register("isGuest")}
                        >
                          <option hidden>Choose options</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name="isGuest"
                          render={({ message }) => (
                            <p className="text-danger fs-3 mt-2">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    {
                      isRoyalGame &&
                      <>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="guessQuestionTimer" className="form-control-label">
                              Guess Question Timer (seconds)
                            </label>
                            <input
                              id="guessQuestionTimer"
                              type="number"
                              min="10"
                              max="100"
                              className="form-control"
                              {...register("guessQuestionTimer", {
                                valueAsNumber: true,
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="guessQuestionTimer"
                              render={({ message }) => (
                                <p className="text-danger fs-3 mt-2">{message}</p>
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="mcqQuestionTimer" className="form-control-label">
                              MCQ Question Timer (seconds)
                            </label>
                            <input
                              id="mcqQuestionTimer"
                              type="number"
                              min="10"
                              max="100"
                              className="form-control"
                              {...register("mcqQuestionTimer", {
                                valueAsNumber: true,
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="mcqQuestionTimer"
                              render={({ message }) => (
                                <p className="text-danger fs-3 mt-2">{message}</p>
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="noOfFreePerks" className="form-control-label">
                              No. Of Free Perks
                            </label>
                            <input
                              id="noOfFreePerks"
                              type="number"
                              min="1"
                              max="10"
                              className="form-control"
                              {...register("noOfFreePerks", {
                                valueAsNumber: true,
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="noOfFreePerks"
                              render={({ message }) => (
                                <p className="text-danger fs-3 mt-2">{message}</p>
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="buyLimit" className="form-control-label">
                              Buy Limit
                            </label>
                            <input
                              id="buyLimit"
                              type="number"
                              min="1"
                              max="999999999"
                              className="form-control"
                              {...register("buyLimit", {
                                valueAsNumber: true,
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="buyLimit"
                              render={({ message }) => (
                                <p className="text-danger fs-3 mt-2">{message}</p>
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="buyPrice" className="form-control-label">
                              Buy Price (Coins)
                            </label>
                            <input
                              id="buyPrice"
                              type="number"
                              min="1"
                              max="999999999"
                              className="form-control"
                              {...register("buyPrice", {
                                valueAsNumber: true,
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="buyPrice"
                              render={({ message }) => (
                                <p className="text-danger fs-3 mt-2">{message}</p>
                              )}
                            />
                          </div>
                        </div>


                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="lastWinnerReward" className="form-control-label">
                              Last Winner Reward (Coins)
                            </label>
                            <input
                              id="lastWinnerReward"
                              type="number"
                              min="1"
                              max="999999999"
                              className="form-control"
                              {...register("lastWinnerReward", {
                                valueAsNumber: true,
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="lastWinnerReward"
                              render={({ message }) => (
                                <p className="text-danger fs-3 mt-2">{message}</p>
                              )}
                            />
                          </div>
                        </div>

                      </>
                    }
                    {
                      isFriendlyGame &&
                      <>
                        <div className="mt-5 col-12 col-md-12 col-lg-12 mb-4">
                          <div className="row">
                            <div className="flex">
                              <div className="mt-3 col-12 col-md-2 col-lg-2">
                                <label className='label-bold'>Coin Reward</label>
                              </div>
                              <div className="col-12 col-md-3 col-lg-3 mb-4">
                                <div>
                                  <label className='label-bold'>1st place</label>
                                </div>
                                <input
                                  required
                                  type="number"
                                  min="0"
                                  max="999999999"
                                  placeholder={`1st place`}
                                  className="form-control"
                                  {...register(`coinReward1`, {
                                    valueAsNumber: true,
                                  })}
                                />
                              </div>
                              <div className="col-12 col-md-3 col-lg-3 mb-4">
                                <div>
                                  <label className='label-bold'>2nd place</label>
                                </div>
                                <input
                                  required
                                  type="number"
                                  min="0"
                                  max="999999999"
                                  placeholder={`2nd place`}
                                  className="form-control"
                                  {...register(`coinReward2`, {
                                    valueAsNumber: true,
                                  })}
                                />
                              </div>
                              <div className="col-12 col-md-3 col-lg-3 mb-4">
                                <div>
                                  <label className='label-bold'>3rd place</label>
                                </div>
                                <input
                                  required
                                  type="number"
                                  min="0"
                                  max="999999999"
                                  placeholder={`3rd place`}
                                  className="form-control"
                                  {...register(`coinReward3`, {
                                    valueAsNumber: true,
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }

                  </div>
                  <div className="btn_group mt-5 d-flex justify-content-center align-items-center pb-5">
                    <button
                      type="button"
                      className="btn-custom btn-danger btn-secondary mx-2"
                      onClick={() => Navigate("/listGameMode")}
                    >
                      Cancel
                    </button>
                    {isSubmitSuccessful ? (
                      <span className="loader"></span>
                    ) : (
                      <button type="submit" className="btn-custom btn-submit">
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </div>
      )}
    </Container>
  );
};
export default CreateGameMode;
