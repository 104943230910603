import React, { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";
import "./styles.css";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import ListPlayersServices from "../../../services/Player.services";
import Loading from "../../../components/loading/Loading";

type FormData = {
  username: string;
  createdAt: string;
  countryName: string;
  emailId: string;
  isGuest: boolean;
  _id: string;
  playerId: string;
  facebookId: string;
  coins: number;
  currentLevel: number;
  playerStats: {
    xpPoints: string;
    totalWins: number;
    totalLoss: number;
    createdAt: string;
  };
  supplies: number;
};
const ViewPlayerProfile = () => {
  const [data, setData] = useState<FormData>();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  // fetchAPI
  const fetchAPI = () => {
    ListPlayersServices.getPlayerProfile(id?.split("@")[0]).then((res: any) => {
      setData(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchAPI();
  }, []);
  let totalGamePlayed = 0
  if (data && data.playerStats) {
    totalGamePlayed = data.playerStats.totalWins + data.playerStats.totalLoss;
  }
  let logintype = "";
  logintype = data?.isGuest ? "Guest" : data?.facebookId ? "Facebook" : "Email";
  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="create-time-bank  pb-5">
          <Col>
            <BreadCrumbs
              childItem="View Player Profile"
              parentLink="listPlayers"
              parentItem="List Players"
            />
            <div className="card m-auto text-center">
              <h3 className="page-title text-center">View Player Profile</h3>
              <div className="body-form">
                <form>
                  <div className="table overflow-auto">
                    <table className="table table-bordered mt-5">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <td>{id?.split("@")[1]}</td>
                        </tr>
                        <tr>
                          <th>Username</th>
                          <td>{data?.username}</td>
                        </tr>
                        <tr>
                          <th>Created At</th>
                          <td>{data?.createdAt}</td>
                        </tr>
                        <tr>
                          <th>Country Name</th>
                          <td>{data?.countryName}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{data?.emailId}</td>
                        </tr>
                        <tr>
                          <th>Login Type</th>
                          <td>{logintype}</td>
                        </tr>
                        <tr>
                          <th>Player Unique Id</th>
                          <td>{data?.playerId}</td>
                        </tr>
                        {/* <tr>
                          <th>Facebook Id</th>
                          <td>{data?.facebookId}</td>
                        </tr> */}
                        <tr>
                          <th>Total Coins</th>
                          <td>{data?.coins}</td>
                        </tr>
                        <tr>
                          <th>Player XP Level</th>
                          <td>{data?.currentLevel}</td>
                        </tr>
                        <tr>
                          <th>Total XP</th>
                          <td>{data?.playerStats?.xpPoints}</td>
                        </tr>
                        <tr>
                          <th>No. Of Supplies</th>
                          <td>{data?.supplies}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Last Played At</th>
                          <td>{data?.playerStats?.createdAt}</td>
                        </tr>
                        <tr>
                          <th>Total No. Of Games Played</th>
                          <td>{totalGamePlayed}</td>
                        </tr>
                        <tr>
                          <th>Total No. of Wins</th>
                          <td>{data?.playerStats?.totalWins}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Link to="/listPlayers">
                    <button
                      type="button"
                      className="btn-custom btn-secondary mx-2 mb-5 my-5"
                    >
                      Back
                    </button>
                  </Link>
                </form>
              </div>
            </div>
          </Col>
        </div>
      )}
    </Container>
  );
};

export default ViewPlayerProfile;
