import { Button, Col, TabContainer } from "react-bootstrap";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useQuery } from '@tanstack/react-query';
import { toast } from "react-toastify";
import { getMiniTournamentReport } from "../../../services/MiniManagement.services";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import Config from "../../../config/config";
import moment from "moment";
import { GrView } from "react-icons/gr";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading/Loading";

const LIMIT_PAGE = Config.limitPage;

const TournamentReport = () => {
  const [tableData, setTableData] = useState<any[]>([])
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<any>()

  const { isLoading, refetch } = useQuery(["GET_TOURNAMENT_CONFIG", page], () => getMiniTournamentReport(page + 1),
    {
      onSuccess: (res) => {
        if (res.data.status) {
          setTableData(res.data.tournaments);
          setCount(res.data.count)
        }
      },
      onError: () => {
        toast.error("Connect to server failed.");
      },
    });
  const handlePageChange = (e: { selected: number }) => {
    const { selected } = e;
    setPage(selected);
  };
  const totalPage = Math.ceil(count / LIMIT_PAGE);
  const displayData = tableData
    ?.map(
      (
        {
          _id,
          status,
          numberOfPlayers,
          tournamentStartTime,
          tournamentEndTime,
          tournamentId,
          winnerPlayerData,
          updatedAt
        },
        index: any
      ) => {
        return (
          <tr key={index}>
            <td>{LIMIT_PAGE * page + (index + 1)}</td>
            <td>{tournamentId}</td>
            <td>{moment(tournamentStartTime).format("DD/MM/YYYY HH:mm")}</td>
            <td>{status === "SCHEDULE_STARTED" ? "WAITING" :
              status === "FREEZED" ? status :
                status === "STARTED" ? "HAPPENING" :
                  status == "SCHEDULE_STOPPED" ? "CLOSED (before happening)" : moment(tournamentEndTime ? tournamentEndTime : updatedAt).format("DD/MM/YYYY HH:mm")}</td>
            <td>{numberOfPlayers}</td>
            <td>{winnerPlayerData ? winnerPlayerData.username : "--"}</td>
            <td>
              <Link to={`/detailTournament/${tournamentId}`}>
                <Button className=" action-icon" variant="outline-info">
                  <GrView />
                </Button>
              </Link>
            </td>
          </tr>
        );
      }
    );
  return (
    <>
      <TabContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="list-tournament pb-5">
            <Col>
              <BreadCrumbs
                childItem="Report"
                parentItem="Mini Tournament Management"
                parentLink="reportTournament" />
              <div className="card m-auto custom onlyforthis">
                <div className="portlet-title">
                  <div className='title-wrapper'>
                    <h3 className="page-title">Mini Tournament Report</h3>
                  </div>
                </div>
                {/* <div className="search-form mt-5">
                    <div className="form">
                      <form className="">
                        <div className="d-flex justify-content-center flex-wrap">
                          <div className="col-3 col-3-custom mx-2 my-2">
                            <DatePicker
                              showTimeSelect
                              dateFormat="dd-MM-yyyy - HH':'mm "
                              placeholderText={`${formatDate(new Date())}`}
                              name="startTime"
                              onKeyDown={preventSerachChange}
                              className="form-custom-input date_picker date-list w-100"
                              selected={startDate}
                              onChange={(e) => handleStartDate(e)}
                            />
                          </div>
                          <div className="col-3 col-3-custom mx-2 my-2">
                            <DatePicker
                              showTimeSelect
                              dateFormat="dd-MM-yyyy - HH':'mm "
                              minDate={startDate}
                              name="endTime"
                              onKeyDown={preventSerachChange}
                              placeholderText={`${formatDate(new Date())}`}
                              className="form-custom-input date_picker date-list w-100"
                              selected={endDate}
                              onChange={(e) => handleEndDate(e)}
                            />
                          </div>
                          <div className=" col-4 col-md-3 col-lg-2 mx-2 my-2">
                            <input
                              type="text"
                              onKeyDown={preventSerachChange}
                              name="tournamentId"
                              className="form-custom-input search"
                              placeholder="Tournament ID"
                              value={formValue.tournamentId}
                              onChange={onInputChange}
                            />
                          </div>
                          <div className=" col-4 col-md-4 col-lg-2 mx-2 my-2">
                            <select
                              className="form-custom-input search"
                              name="gameType"
                              onChange={onInputChange}
                            >
                              <option hidden>Game Type</option>
                              {gameVariationArr?.map((data, index) => (
                                <option key={index} value={data.value}>
                                  {data.title}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className=" col-4 col-md-4 col-lg-2 mx-2 my-2">
                            <input
                              type="number"
                              onKeyDown={blockInvalidChar}
                              name="playerNumber"
                              value={formValue.playerNumber}
                              className="form-custom-input search"
                              placeholder="No. Of Player"
                              min={1}
                              onChange={onInputChange}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center flex-wrap">
                          <div className=" col-3 col-md-3 col-lg-3 col-3-custom mx-2 my-2">
                            <select
                              className="form-custom-input search"
                              name="tournamentType"
                              value={formValue.tournamentType}
                              onChange={onInputChange}
                            >
                              <option hidden>Tournament Type</option>
                              {tournamentTypeArr?.map((data, index) => (
                                <option key={index} value={data.value}>
                                  {data.title}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className=" col-3 col-md-3 mx-2 my-2 col-lg-3 col-3-custom">
                            <select
                              className="form-custom-input search"
                              name="isReentryAllowed"
                              onChange={onInputChange}
                              value={formValue.isReentryAllowed}
                            >
                              <option hidden>Re-Entry</option>
                              {arrayTrueFalse?.map((data, index) => (
                                <option key={index} value={data.value}>
                                  {data.title}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className=" col-3 col-md-3 mx-2 my-2 col-lg-3 col-3-custom">
                            <select
                              className="form-custom-input search"
                              name="isRebuyAllowed"
                              onChange={onInputChange}
                              value={formValue.isRebuyAllowed}
                            >
                              <option hidden>Re-Buy</option>
                              {arrayTrueFalse?.map((data, index) => (
                                <option key={index} value={data.value}>
                                  {data.title}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className=" col-3 col-md-3 mx-2 my-2 col-lg-3 col-3-custom">
                            <select
                              className="form-custom-input search"
                              name="isAddonTimeEnabled"
                              onChange={onInputChange}
                              value={formValue.isAddonTimeEnabled}
                            >
                              <option hidden>Add-On</option>
                              {arrayTrueFalse?.map((data, index) => (
                                <option key={index} value={data.value}>
                                  {data.title}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className=" col-3 col-md-3 mx-2 my-2 col-lg-3 col-3-custom">
                            <select
                              className="form-custom-input search"
                              name="isGtdEnabled"
                              onChange={onInputChange}
                              value={formValue.isGtdEnabled}
                            >
                              <option hidden>Guaranteed</option>
                              {arrayTrueFalse?.map((data, index) => (
                                <option key={index} value={data.value}>
                                  {data.title}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className=" col-3 mx-2 my-2 col-lg-3 col-3-custom">
                            <select
                              className="form-custom-input search"
                              name="state"
                              onChange={onInputChange}
                              value={formValue.state}
                            >
                              <option hidden>Game State</option>
                              <option value="REGISTER">REGISTER</option>
                              <option value="RUNNING">RUNNING</option>
                              <option value="PUBLISHED">PUBLISHED</option>
                              <option value="UPCOMING">UPCOMING</option>
                              <option value="CLOSED">CLOSED</option>
                              <option
                                value="Open To Register"
                                className="text-uppercase"
                              >
                                Open To Register
                              </option>
                              <option
                                value="Registration Freezed"
                                className="text-uppercase"
                              >
                                Registration Freezed
                              </option>
                              <option
                                value="Open To Late Register"
                                className="text-uppercase"
                              >
                                Open To Late Register
                              </option>
                              <option value="CANCELED">CANCELED</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex justify-content-center mt-5">
                          <button
                            type="button"
                            className="btn-custom btn-primary btn-search"
                            onClick={handleSearch}
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn-custom btn-primary btn-reset"
                            onClick={handleResetFormSearch}
                          >
                            Reset
                          </button>
                        </div>
                      </form>
                    </div>
                  </div> */}
                <div className="table-data ">
                  <table className="table table-bordered mt-5">
                    <thead>
                      <tr className="trTournament">
                        <th>S.No.</th>
                        <th>Tournament ID</th>
                        <th>Start Date and Time</th>
                        <th>End Date and Time</th>
                        <th>No. Of Player</th>
                        <th>Winner</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>{displayData}</tbody>
                  </table>
                </div>
              </div>
            </Col>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              forcePage={page}
              pageCount={totalPage}
              onPageChange={handlePageChange}
              activeClassName={"page-item active"}
              disabledClassName={"page-item disabled"}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              pageLinkClassName={"page-link"}
            />
          </div>
        )}
        {/* <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>Do you want to delete ?</Modal.Body>
            <Modal.Footer>
              <button className="btn-custom btn-secondary" onClick={handleClose}>
                Close
              </button>
              <button className="btn-custom btn-danger" onClick={handleDelete}>
                Remove
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={showCancel} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>Do you want to cancel ?</Modal.Body>
            <Modal.Footer>
              <button className="btn-custom btn-secondary" onClick={handleClose}>
                Close
              </button>
              <button className="btn-custom btn-danger" onClick={handleCancel}>
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
  
          <Modal show={showPublished} onHide={handleClosePublished}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>Do you want to publish ?</Modal.Body>
            <Modal.Footer>
              <button
                className="btn-custom btn-secondary"
                onClick={handleClosePublished}
              >
                Close
              </button>
              <button className="btn-custom btn-info" onClick={handlePublished}>
                Publish
              </button>
            </Modal.Footer>
          </Modal> */}
      </TabContainer>
    </>
  )
}
export default TournamentReport