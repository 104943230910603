

import { Col, TabContainer, Button, Table } from "react-bootstrap";
import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import "./royal.css"
import { useEffect, useState } from "react";
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Loading from "../../components/loading/Loading";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { getOneRoyalGameReport } from "../../services/RoyalGameConfig.services";
const ACTIVES = ["STARTED"];
const INACTIVES = ["CLOSED", "SCHEDULE_STOPPED", "STOPPED"];

const RoyalGameReportDetail = () => {
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
    const [arExpanded, setArExpanded] = useState<{ [key: number]: boolean }>({});
    const [grExpanded, setGrExpanded] = useState<{ [key: number]: boolean }>({});

    const { gameId } = useParams();
    const [tableData, setTableData] = useState<string[]>([])
    const [status, setStatus] = useState<string>("");
    const [royalGame, setRoyalGame] = useState<any>();
    const [royalGameSession, setRoyalGameSession] = useState<any>();
    const [lastestARPhase, setLastestARPhase] = useState<any>();
    const [guessingGameData, setGuessingGameData] = useState<any[]>([]);
    const [answeringGameData, setAnsweringGameData] = useState<any[]>([]);

    const [countTime, setCountTime] = useState<string>("0s");

    const { isLoading, refetch } = useQuery(["GET_ROYALGAME_CONFIG"], () => getOneRoyalGameReport(gameId ? gameId : ""), {
        onSuccess: (res) => {
            const { data, status } = res;
            if (data?.status) {
                setLastestARPhase(data?.lastestARPhase)
                setTableData(data.rounds)
                setRoyalGame(data?.royalGame);
                setRoyalGameSession(data?.royalGameSession);
                setGuessingGameData(data?.royalGameSession?.guessingGameData);
                setAnsweringGameData(data?.royalGameSession?.answeringGameData);
                setStatus(data?.royalGame.status);
            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    });

    const toggleExpand = (index: number) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const toggleArExpand = (index: number) => {
        setArExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const toggleGrExpand = (index: number) => {
        setGrExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };


    const onReload = () => {
        refetch();
    }

    const countTrue = (playerAnswers: any[]) => {
        return playerAnswers?.filter(ele => !!ele.isRight).length || 0;
    }

    const countFalse = (playerAnswers: any[]) => {
        return playerAnswers?.filter(ele => !ele.isRight).length || 0;
    }

    const tick = () => {
        const currentTime = (new Date()).getTime();
        const startTime = new Date(royalGame?.startTime).getTime();

        const diffTime = currentTime - startTime;
        const d = Number(diffTime / 1000);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);

        const hDisplay = h > 0 ? `${h}h ` : "";
        const mDisplay = m > 0 ? `${m}m ` : "";
        const sDisplay = s > 0 ? `${s}s ` : "";

        const final = hDisplay + mDisplay + sDisplay;

        setCountTime(final);
    }
    const viewLogic = () => {
        if (ACTIVES.includes(status)) {
            return (
                <div className="col-lg-6 col-sm-6 col-xs-12 px-4 mb-5">
                    <div className="mb-4">
                        <h4><b>Current Royal Game Running Since:</b> {moment(royalGame?.startTime).format("DD/MM/YYYY HH:mm")} - {countTime}</h4>
                    </div>
                    <div className="mb-4">
                        <h4><b>Status:</b> HAPPENING </h4>
                    </div>
                    <Button onClick={onReload} className=" action-icon" variant="outline-info">
                        Reload
                    </Button>
                </div>
            );
        }
        return (
            <div className="col-lg-6 col-sm-6 col-xs-12 px-4 mb-5">
                <div className="mb-4">
                    <h2><b>Start Time:</b> {moment(royalGame?.startTime).format("DD/MM/YYYY HH:mm")} </h2>
                    <h2><b>End Time:</b> {royalGame?.endTime && !isNaN(royalGame?.endTime) ?
                        moment(royalGame?.endTime).format("DD/MM/YYYY HH:mm") :
                        royalGame?.updatedAt && !isNaN(royalGame?.updatedAt) ?
                            moment(royalGame?.updatedAt).format("DD/MM/YYYY HH:mm") :
                            "N/A"}
                    </h2>
                    <h2><b>Status:</b> {status === "SCHEDULE_STARTED" ? "WAITING" :
                        status === "FREEZED" ? status :
                            status === "STARTED" ? "HAPPENING" :
                                status == "SCHEDULE_STOPPED" ? "CLOSED (before happening)" : status} </h2>
                    <h2><b>Winner:</b> {royalGame?.winner?.username ? royalGame?.winner?.username : "N/A"} </h2>
                </div>
            </div>

        );
    }

    useEffect(() => {

        var timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    }, [countTime]);
    return (
        <>
            <TabContainer>
                {false ? (
                    <Loading />
                ) : (
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs
                                childItem="Current Royal Game"
                                parentItem="Report"
                                parentLink="reportRoyalGame" />
                            <div className="card m-auto custom onlyforthis">
                                <div className="portlet-title">
                                    <div className='title-wrapper'>
                                        <h3 className="page-title"><b>Current Royal Game</b></h3>
                                    </div>
                                </div>
                                <div className="row px-4 my-4">
                                    <div className="col-lg-6 col-sm-6 col-xs-12 px-4 mb-5">
                                        <div className="mb-4">
                                            <h2><b>Total No. Of Players:</b> {royalGameSession?.noOfPlayers ? royalGameSession?.noOfPlayers : 0}</h2>
                                            <h2><b>Game ID:</b> {royalGame?.gameId} </h2>
                                            <h2><b>Session ID:</b> {royalGameSession?.gameSessionId ? royalGameSession?.gameSessionId : "N/A"} </h2>
                                        </div>
                                        <div className="mb-4">
                                            <div className="rsitemz"><b>Answering room:</b> {lastestARPhase?.answeringRoomPlayerIds?.length ? lastestARPhase?.answeringRoomPlayerIds?.length : 0} </div>
                                            <div className="rsitemz"><b>Guessing room:</b> {lastestARPhase?.guessingRoomPlayerIds?.length ? lastestARPhase?.guessingRoomPlayerIds?.length : 0} </div>
                                            <div className="rsitemz"><b>Answering Queue:</b> {lastestARPhase?.willBackToAnsweringPlayerIds?.length ? lastestARPhase?.willBackToAnsweringPlayerIds?.length : 0} </div>
                                            <div className="rsitemz"><b>Guessing Queue:</b> {lastestARPhase?.willGuessingRoomPlayerIds?.length ? lastestARPhase?.willGuessingRoomPlayerIds?.length : 0} </div>
                                            <div className="rsitemz"><b>Eliminated:</b> {lastestARPhase?.eliminatedPlayerIds?.length ? lastestARPhase?.eliminatedPlayerIds?.length : 0} </div>
                                            {lastestARPhase?.willGuessingRoomPlayerIds?.length > 0 &&
                                                <small>The remaining players in the Guessing Queue, due to not having enough 3 players to create a guessing session until the game ends, are still counted as eliminated.</small>
                                            }
                                        </div>
                                    </div>
                                    {viewLogic()}
                                </div>
                                <List>
                                    <div className="flex">
                                        <ListItem onClick={() => toggleExpand(1)} >
                                            {expanded[1] ? <AddCircleOutlineIcon className="icon" /> : <RemoveCircleOutlineOutlinedIcon className="icon" />}
                                            <ListItemText
                                                primary={"ANSWERING"}
                                                primaryTypographyProps={{ style: { fontSize: '20px', marginLeft: '5px', fontWeight: "700" } }}
                                            />
                                        </ListItem>
                                    </div>
                                    <Collapse in={expanded[1]} style={{ marginLeft: '20px', fontWeight: '500' }} >
                                        {
                                            answeringGameData?.length > 0 && answeringGameData?.map((eleAr: any, indexAr: number) => {
                                                return (
                                                    <div className="flexcustom" key={indexAr}>
                                                        <div>
                                                            <div className="rsitemz mb-2"><b>Question {indexAr + 1}:</b> {eleAr?.mcqQuestion} </div>
                                                            <div className="rsitemz mb-2"><b>Options:</b>
                                                                {eleAr?.options && (eleAr?.options as Array<string>)?.map((eleOpt, indexOpt) => {
                                                                    return (
                                                                        <span key={indexOpt} className="label mt-3 mb-3"><a className="loptions">{eleOpt}</a></span>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div className="rsitemz mb-2"><b>Correct Answer:</b> <span className="label mt-3 mb-3"><a className="loptions">{eleAr?.correctAnswer} </a></span> </div>

                                                            <div className="mt-4 mb-4">
                                                                <span className="label continue">Continue: {countTrue(eleAr?.playerAnswers)}</span>
                                                                <span className="label eliminated">K.O: {countFalse(eleAr?.playerAnswers)}</span>
                                                            </div>
                                                            <div className="mt-3 mb-3">
                                                                <Button size="lg" variant="outline-success" onClick={() => toggleArExpand(indexAr)}>{arExpanded[indexAr] ? 'Hide' : 'Show'} Players' Answers</Button>
                                                            </div>
                                                        </div>


                                                        <Collapse in={arExpanded[indexAr]} style={{ marginLeft: '20px', fontWeight: '500' }} >
                                                            <Table bordered hover className="custom-width-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>User Name</th>
                                                                        <th>Answer</th>
                                                                        <th>Right?</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        eleAr?.playerAnswers?.map((eleplayer: any, indexAns: number) => {
                                                                            return (
                                                                                <tr key={indexAns}>
                                                                                    <td>{eleplayer.username}</td>
                                                                                    <td>{eleplayer.MCQ}</td>
                                                                                    <td className={`td-${String(eleplayer.isRight)}`}>{String(eleplayer.isRight)}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </Table>
                                                        </Collapse>

                                                    </div>
                                                )
                                            })
                                        }

                                    </Collapse>
                                </List>


                                <List>
                                    <div className="flex">
                                        <ListItem onClick={() => toggleExpand(2)} >
                                            {expanded[2] ? <AddCircleOutlineIcon className="icon" /> : <RemoveCircleOutlineOutlinedIcon className="icon" />}
                                            <ListItemText
                                                primary={"GUESSING"}
                                                primaryTypographyProps={{ style: { fontSize: '20px', marginLeft: '5px', fontWeight: "700" } }}
                                            />
                                        </ListItem>
                                    </div>
                                    <Collapse in={expanded[2]} style={{ marginLeft: '20px', fontWeight: '500' }} >
                                        <div className="mb-4">
                                            <span className="label continue">Continue</span>
                                            <span className="label eliminated">Eliminated</span>
                                        </div>
                                        {
                                            guessingGameData?.length > 0 && guessingGameData?.map((eleGr: any, indexGr: number) => {
                                                return (
                                                    <div className="flexcustom" key={indexGr}>
                                                        <div>
                                                            <div className="rsitemz"><b>Question {indexGr + 1}:</b> {eleGr?.guessQuestion} </div>
                                                            <div className="rsitemz"><b>Correct Answer:</b> <span className="label mt-3 mb-3"><a className="loptions">{eleGr?.correctAnswer}</a> </span></div>
                                                            <div className="rsitemz"><b>Guess Session ID:</b> {eleGr?.guessingSessionId} </div>
                                                            <div className="mt-3 mb-3">
                                                                <Button size="lg" variant="outline-success" onClick={() => toggleGrExpand(indexGr)}>{grExpanded[indexGr] ? 'Hide' : 'Show'} Players' Answers</Button>
                                                            </div>

                                                        </div>


                                                        <div>
                                                            <Collapse in={grExpanded[indexGr]} style={{ marginLeft: '20px', fontWeight: '500' }} >
                                                                <Table bordered hover className="custom-width-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>User Name</th>
                                                                            <th>Submitted</th>
                                                                            <th>Difference</th>
                                                                            <th>Time Taken (ms)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            eleGr?.playerAnswers?.map((eleplayer: any, indexAns: number) => {
                                                                                return (
                                                                                    <tr key={indexAns}>
                                                                                        <td className={`tr-${eleplayer?.rank}`}>{eleplayer.username}</td>
                                                                                        <td className={`tr-${eleplayer?.rank}`}>{eleplayer.answerSubmitted}</td>
                                                                                        <td className={`tr-${eleplayer?.rank}`}>{eleplayer.answerDifference}</td>
                                                                                        <td className={`tr-${eleplayer?.rank}`}>{String(eleplayer.timeTaken)}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }

                                                                    </tbody>
                                                                </Table>
                                                            </Collapse>

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Collapse>
                                </List>
                            </div>

                        </Col>
                    </div>
                )}
            </TabContainer>
        </>
    );
}
export default RoyalGameReportDetail;