import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Col, TabContainer, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Loading from '../../components/loading/Loading';
import BreadCrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { useForm } from 'react-hook-form';
import GameModeService from '../../services/GameMode.services';
import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ErrorMessage } from "@hookform/error-message";
import { RoyalGameConfigSchema } from "../../utils/schemaValidation";
import { yupResolver } from "@hookform/resolvers/yup";

import "./royal.css"
import { useNavigate } from 'react-router-dom';
interface IRoyalGameConfig {
    name: string,
    isRankedGame: string,
    isDisabled: boolean,
    supplyRequired: number,
    unlockLevel: number,
    isGuest: boolean,
    gameModeId: string,
    createdAt: string,
    updatedAt: string,
    guessQuestionTimer: number,
    mcqQuestionTimer: number,
    buyLimit: number,
    buyPrice: number,
    noOfFreePerks: number,
    lastWinnerReward: number
}

const defaultValue = {
    name: "",
    isRankedGame: "",
    isDisabled: false,
    supplyRequired: NaN,
    unlockLevel: NaN,
    isGuest: false,
    gameModeId: "",
    createdAt: "",
    updatedAt: "",
    guessQuestionTimer: NaN,
    mcqQuestionTimer: NaN,
    buyLimit: NaN,
    buyPrice: NaN,
    noOfFreePerks: NaN,
    lastWinnerReward: NaN
}
interface IBeValidationError { message: string, field: string }

const RoyalGame: React.FC = () => {
    const Navigate = useNavigate();
    const [gameModeId, setGameModeId] = useState<string>("");
    const [updatedAt, setUpdatedAt] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const [beValidationErrors, setBeValidationErrors] = useState<Array<IBeValidationError>>([]);
    const [date, setDate] = useState(new Date());

    const { register, handleSubmit, setValue, watch, setError, setFocus, formState: { errors, isSubmitSuccessful } } = useForm<IRoyalGameConfig>({
        resolver: yupResolver(RoyalGameConfigSchema),
        defaultValues: defaultValue,
    });

    const { refetch, isLoading } = useQuery(["GET_ROYALGANE_CONFIG"], () => GameModeService.getRoyalConfig(), {
        onSuccess: (res) => {
            const { data } = res;
            if (res.data) {
                const {
                    name,
                    isRankedGame,
                    isDisabled,
                    supplyRequired,
                    unlockLevel,
                    isGuest,
                    gameModeId,
                    updatedAt,
                    guessQuestionTimer,
                    mcqQuestionTimer,
                    buyLimit,
                    buyPrice,
                    noOfFreePerks,
                    lastWinnerReward
                }: IRoyalGameConfig = data;
                setValue("supplyRequired", supplyRequired);
                setValue("unlockLevel", unlockLevel);
                setValue("name", name);
                setValue("isRankedGame", isRankedGame);
                setValue("isGuest", isGuest);
                setValue("isDisabled", isDisabled);
                setValue("gameModeId", gameModeId);

                setValue("guessQuestionTimer", guessQuestionTimer);
                setValue("mcqQuestionTimer", mcqQuestionTimer);
                setValue("buyLimit", buyLimit);
                setValue("buyPrice", buyPrice);
                setValue("noOfFreePerks", noOfFreePerks);
                setValue("lastWinnerReward", lastWinnerReward);

                setGameModeId(gameModeId);
                setUpdatedAt(updatedAt);

            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    });

    const tick = () => {
        setDate(new Date());
    }

    useEffect(() => {
        var timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    }, [setFocus, beValidationErrors]);

    const onSubmit = (data: IRoyalGameConfig) => {
        setLoading(true);
        GameModeService.updateGameMode(data, gameModeId).then(
            (res: any) => {
                if (res._id) {
                    toast.success("Updated Successfully");
                    // apply now
                    onApplyGameModesSettings();
                }
            },
            (err) => {
                toast.error("Updated Fail");
            }
        );
    };
    const onApplyGameModesSettings = async () => {
        GameModeService.applyGameModes().then(result => {
            if (result?.data?.success) {
                toast.success("Applied Successfully!");
                setTimeout(() => {
                    setLoading(false);
                    Navigate("/listGameMode");
                }, 200);
            }
            else {
                toast.error("Applied Failed!");
            }

        }).catch(e => {
            toast.error("Something went wrong");
            console.log(e);
        });

    }
    return (
        <>
            <TabContainer>
                {isLoading || loading ? (
                    <Loading />
                ) : (
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs
                                childItem="Config"
                                parentItem="Royal Game Management"
                                parentLink="reportTournament" />
                            <div className="card m-auto custom onlyforthis">
                                <div className="portlet-title">
                                    <div className='title-wrapper'>
                                        <h3 className="page-title">Royal Game Config</h3>
                                    </div>
                                </div>

                                <div className="form-search">
                                    <div className='flex justify-content-center mb-3'>
                                        <h3>Current Local Time: {moment(date).format("HH:mm:ss (UTCZ)")}</h3>
                                    </div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Game Mode Name
                                                </label>
                                                <input
                                                    id="name"
                                                    type="text"
                                                    placeholder="Game Mode Name"
                                                    className="form-control"
                                                    {...register("name")}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Is Disabled
                                                </label>
                                                <select
                                                    id="isDisabled"
                                                    className="form-control"
                                                    {...register("isDisabled")}>
                                                    <option hidden>Choose options</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="isDisabled"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Is Ranked Game
                                                </label>
                                                <select
                                                    id="isRankedGame"
                                                    disabled
                                                    className="form-control"
                                                    {...register("isRankedGame")}>
                                                    <option hidden>Choose options</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="isRankedGame"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Supplies Required
                                                </label>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="Supplies Required"
                                                    className="form-control"
                                                    {...register("supplyRequired", { valueAsNumber: true })}
                                                    min={0}
                                                    max={1000}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="supplyRequired"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Unlock Level
                                                </label>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="Unlock Level"
                                                    className="form-control"
                                                    {...register("unlockLevel", { valueAsNumber: true })}
                                                    min={0}
                                                    max={100}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="unlockLevel"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Is Guest
                                                </label>
                                                <select
                                                    id="isGuest"
                                                    className="form-control"
                                                    {...register("isGuest")}>
                                                    <option hidden>Choose options</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="isGuest"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>

                                            <div className="mt-5 mb-5"></div>
                                            {/* other config */}
                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Guess Question Timer (seconds)
                                                </label>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="Guess Question Timer (seconds)"
                                                    className="form-control"
                                                    {...register("guessQuestionTimer", { valueAsNumber: true })}
                                                    min={10}
                                                    max={100} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="guessQuestionTimer"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    MCQ Question Timer (seconds)
                                                </label>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="MCQ Question Timer (seconds)"
                                                    className="form-control"
                                                    {...register("mcqQuestionTimer", { valueAsNumber: true })}
                                                    min={10}
                                                    max={100}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="mcqQuestionTimer"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    No. Of Free Perks
                                                </label>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="No. Of Free Perks"
                                                    className="form-control"
                                                    {...register("noOfFreePerks", { valueAsNumber: true })}
                                                    min={0}
                                                    max={100}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="noOfFreePerks"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Buy Limit
                                                </label>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="Buy Limit"
                                                    className="form-control"
                                                    {...register("buyLimit", { valueAsNumber: true })}
                                                    min={0}
                                                    max={100}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="buyLimit"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Buy Price (Coins)
                                                </label>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="Buy Price (Coins)"
                                                    className="form-control"
                                                    {...register("buyPrice", { valueAsNumber: true })}
                                                    min={0}
                                                    max={9999999999}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="buyPrice"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                <label className="label-bold">
                                                    Last Winner Reward (Coins)
                                                </label>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="Last Winner Reward (Coins)"
                                                    className="form-control"
                                                    {...register("lastWinnerReward", { valueAsNumber: true })}
                                                    min={0}
                                                    max={9999999999}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="lastWinnerReward"
                                                    render={({ message }) => (
                                                        <p className="text-danger fs-3 mt-2">{message}</p>
                                                    )}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-12 mt-3 mb-3">
                                            Last updated: {moment(updatedAt).format("YYYY-MM-DD HH:mm:ss (UTCZ)")}
                                        </div>
                                        <div className="button-group flex mt-4">
                                            <Button size="lg" variant="success" type="submit" disabled={isLoading || loading}>
                                                Submit
                                            </Button>
                                            <Button size="lg" onClick={() => Navigate("/listGameMode")} variant="primary" type="button" disabled={isLoading || loading}>
                                                Back
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </div>
                )}
            </TabContainer>
        </>

    );
};

export default RoyalGame;
