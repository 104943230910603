import * as yup from "yup";
export const createGameModeSchema = yup.object({
  name: yup.string().required("This field is required!"),
  isRankedGame: yup.string().required("This field is required!"),
  supplyRequired: yup.number().when("isRankedGame", {
    is: "Yes",
    then: yup
      .number()
      .min(1, "Value must be greater 1")
      .required("This field is required!")
      .typeError("Value must be a number"),
    otherwise: yup.number().nullable(),
  }),
  unlockLevel: yup
    .number()
    .min(0, "Value must be greater 0")
    .required("This field is required!")
    .typeError("Value must be a number"),
  isGuest: yup.boolean().required("This field is required!"),
});


export const RoyalGameConfigSchema = yup.object({
  name: yup.string().required("This field is required!"),
  isRankedGame: yup.string().required("This field is required!"),
  supplyRequired: yup.number().when("isRankedGame", {
    is: "Yes",
    then: yup
      .number()
      .min(1, "Value must be greater 1")
      .required("This field is required!")
      .typeError("Value must be a number"),
    otherwise: yup.number().nullable(),
  }),
  unlockLevel: yup
    .number()
    .min(0, "Value must be greater 0")
    .required("This field is required!")
    .typeError("Value must be a number"),
  isGuest: yup.boolean().required("This field is required!"),

  guessQuestionTimer: yup
    .number()
    .min(10, "Value must be greater 10")
    .max(100, "Value must be less than 100")
    .required("This field is required!")
    .typeError("Value must be a number"),
  mcqQuestionTimer: yup
    .number()
    .min(10, "Value must be greater 10")
    .max(100, "Value must be less than 100")
    .required("This field is required!")
    .typeError("Value must be a number"),
  buyLimit: yup
    .number()
    .min(0, "Value must be greater 0")
    .max(100, "Value must be less than 100")
    .required("This field is required!")
    .typeError("Value must be a number"),
  buyPrice: yup
    .number()
    .min(0, "Value must be greater 0")
    .max(9999999999, "Value must be less than 9999999999")
    .required("This field is required!")
    .typeError("Value must be a number"),
  noOfFreePerks: yup
    .number()
    .min(0, "Value must be greater 0")
    .max(100, "Value must be less than 100")
    .required("This field is required!")
    .typeError("Value must be a number"),
  lastWinnerReward: yup
    .number()
    .min(0, "Value must be greater 0")
    .max(9999999999, "Value must be less than 9999999999")
    .required("This field is required!")
    .typeError("Value must be a number"),
});