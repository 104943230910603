import Config from "../config/config";
import AxiosInstance from "../utils/axios/AxiosInstance";

const url = "royalgameconfig"
const urlReport = "royalreport"
const urlDetail = "detail"

export const getRoyalGame = () => {
    return AxiosInstance.get(Config.apiUrl + url);
};

export const createRoyalGame = (data: any) => {
    return AxiosInstance.put(Config.apiUrl + url, data);
};

export const updateRoyalGame = (data: any, _id: string) => {
    delete data?._id
    return AxiosInstance.put(Config.apiUrl + url, data);
}

export const updateStatusScheduling = (data: any) => {
    return AxiosInstance.put(Config.apiUrl + "royalgameconfigstatus", data)
}

// for reports
export const getRoyalGameReport = (skip: number, limit: number, sort: number = -1) =>
    AxiosInstance.get(Config.apiUrl + urlReport, { params: { skip, limit, sort } });

// for one report
export const getOneRoyalGameReport = (gameId: string) =>
    AxiosInstance.get(`${Config.apiUrl}${urlReport}/${gameId}`);
