import { Col, TabContainer } from "react-bootstrap";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import "./questiondetail.css"
import { useEffect, useState } from "react";
import Loading from "../../../../components/loading/Loading";
import { useParams } from "react-router-dom";
import { getQuestionByMatchId } from "../../../../services/MiniManagement.services";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

const QuestionDetail: React.FC = () => {
    const { tournId, matchId } = useParams();
    const [expansionQuestion, setExpansionQuestion] = useState<any[]>();
    const [battleQuestion, setBattleQuestion] = useState<{ mcqQuestion: any[], guessQuestion: any[] }>();
    const parentLink = `detailTournament/${tournId}`;

    const { isLoading, refetch } = useQuery(["GET_TOURNAMENT_CONFIG"], () => getQuestionByMatchId(matchId ? matchId : ""), {
        onSuccess: (res) => {
            const { data, status } = res;
            if (data?.status) {
                setBattleQuestion(data?.gameHistory?.battle);
                setExpansionQuestion(data?.gameHistory?.expansion);
            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    });

    useEffect(() => {

    }, [matchId]);
    return (
        <>
            <TabContainer>
                {false ? (
                    <Loading />
                ) : (
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs
                                childItem="Match Details"
                                parentItem="Mini Tournament Report"
                                parentLink={parentLink} />
                            <div className="card m-auto custom onlyforthis">
                                <div className="portlet-title">
                                    <div className='title-wrapper'>
                                        <h3 className="page-title">Question Logs For {matchId}</h3>
                                    </div>
                                </div>
                                {
                                    expansionQuestion && expansionQuestion?.length > 0 && <div>
                                        <h2 className="row px-4 my-4">
                                            <b>Expansion</b>
                                        </h2>
                                        {
                                            expansionQuestion?.map((ele, index) => {
                                                return (
                                                    <div key={index} className="text px-4 my-4">
                                                        <div className="rsitem"><b>Question {index + 1}:</b> {ele?.guessQuestion}</div>
                                                        <div className="rsitem"><b>Correct Answer:</b> {ele?.correctAnswer}</div>
                                                        <div className="rsitem">
                                                            <b>Players' Answers:</b>
                                                        </div>
                                                        {ele?.winners?.map((player: any, playerIndex: number) => {
                                                            return (
                                                                <div key={playerIndex} className="px-4 my-4 player-wrapper">
                                                                    <div className="rsitem"><b>Player ID:</b> {player?.playerId}</div>
                                                                    <div className="rsitem"><b>User Name:</b> {player?.username}</div>
                                                                    <div className="rsitem"><b>Rank:</b> {player?.rank}</div>
                                                                    <div className="rsitem"><b>Answer:</b> {player?.answerSubmitted}</div>
                                                                    <div className="rsitem"><b>Difference:</b> {player?.answerDifference}</div>
                                                                    <div className="rsitem"><b>Time Taken:</b> {player?.timeTaken}ms</div>
                                                                </div>)
                                                        })}
                                                    </div>

                                                );
                                            })}
                                    </div>
                                }

                                {
                                    battleQuestion && <div>
                                        <h2 className="row px-4 my-4">
                                            <b>Battle</b>
                                        </h2>
                                        {battleQuestion?.mcqQuestion && battleQuestion?.mcqQuestion?.length > 0 &&
                                            <div className="px-4 my-4">
                                                <h3 className="row px-4 my-4"><b>MCQ Question:</b></h3>
                                                {battleQuestion?.mcqQuestion?.map((ele, index) => {
                                                    return (
                                                        <div key={index} className="text px-4 my-4">
                                                            <div className="rsitem"><b>Question {index + 1}:</b> {ele?.mcqQuestion}</div>
                                                            <div className="rsitem"><b>Opptions:</b> {JSON.stringify(ele?.answers)}</div>
                                                            <div className="rsitem"><b>Correct Answer:</b> {ele?.correctAnswer}</div>
                                                            <div className="rsitem">
                                                                <b>Players' Answers:</b>
                                                            </div>
                                                            {ele?.winners?.map((player: any, playerIndex: number) => {
                                                                return (
                                                                    <div key={playerIndex} className="px-4 my-4 player-wrapper">
                                                                        <div className="rsitem"><b>Player ID:</b> {player?.playerId}</div>
                                                                        <div className="rsitem"><b>User Name:</b> {player?.username}</div>
                                                                        <div className="rsitem"><b>Player Field:</b> {player?.playerField}</div>
                                                                        <div className="rsitem"><b>Answer:</b> {player?.MCQ}</div>
                                                                    </div>)
                                                            })}
                                                        </div>

                                                    );
                                                })}
                                            </div>
                                        }
                                        <hr></hr>
                                        {battleQuestion?.guessQuestion && battleQuestion?.guessQuestion?.length > 0 &&
                                            <div className="px-4 my-4">
                                                <h3 className="row px-4 my-4"><b>Guess Question:</b></h3>
                                                {battleQuestion?.guessQuestion?.map((ele, index) => {
                                                    return (
                                                        <div key={index} className="text px-4 my-4">
                                                            <div className="rsitem"><b>Question {index + 1}:</b> {ele?.guessQuestion}</div>
                                                            <div className="rsitem"><b>Correct Answer:</b> {ele?.correctAnswer}</div>
                                                            <div className="rsitem">
                                                                <b>Players' Answers:</b>
                                                            </div>
                                                            {ele?.winners?.map((player: any, playerIndex: number) => {
                                                                return (
                                                                    <div key={playerIndex} className="px-4 my-4 player-wrapper">
                                                                        <div className="rsitem"><b>Player ID:</b> {player?.playerId}</div>
                                                                        <div className="rsitem"><b>User Name:</b> {player?.username}</div>
                                                                        <div className="rsitem"><b>Player Field:</b> {player?.playerField}</div>
                                                                        <div className="rsitem"><b>Answer:</b> {player?.answerSubmitted}</div>
                                                                        <div className="rsitem"><b>Difference:</b> {player?.answerDifference}</div>
                                                                        <div className="rsitem"><b>Time Taken:</b> {player?.timeTaken}ms</div>
                                                                    </div>)
                                                            })}
                                                        </div>

                                                    );
                                                })}
                                            </div>
                                        }

                                    </div>
                                }

                            </div>

                        </Col>
                    </div>
                )}
            </TabContainer>
        </>
    );
}

export default QuestionDetail;