import Config from "../config/config";
import AxiosInstance from "../utils/axios/AxiosInstance";

const getListPlayers = (page: number = 0) => {
  let pageNumber = page * 10;
  return AxiosInstance.get(
    Config.apiUrl + `players?skip=${pageNumber}&limit=10`
  );
};

const getPlayerProfile = (id: any) => {
  return AxiosInstance.get(Config.apiUrl + "players/" + id);
};

const searchListPlayers = (data: any) => {
  const { username, sorting, desc_asc, page } = data;
  let pageNumber = page * 10;

  return AxiosInstance.get(
    Config.apiUrl +
    `players?username=${username}&sortBy=${sorting}&sortOrder=${desc_asc}&skip=${pageNumber}&limit=10`
  );
};

const PlayerServices = {
  getListPlayers,
  getPlayerProfile,
  searchListPlayers,
};

export default PlayerServices;
