import React, { useContext } from "react";
import styles from "./sidebarItem.module.css";
import classNames from "classnames/bind";
import { useNavigate } from "react-router-dom";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { ThemeContext } from "../../../utils/context/ThemeProvider";

const cx = classNames.bind(styles);

interface props {
  name: string;
  index: any;
  destination: any;
}

const SidebarItem = ({ name, index, destination }: props) => {
  const context = useContext(ThemeContext);
  const Navigate = useNavigate();
  const handleClick = () => {
    context?.handleIsActiveMenu();
    Navigate(destination);
  };
  return (
    <li className={cx("sidebar-list-item")} key={index} onClick={handleClick}>
      <IoExtensionPuzzleOutline />
      <span style={{ marginLeft: "5px" }}>{name}</span>
    </li>
  );
};

export default SidebarItem;
