import { useEffect, useState } from "react";
import { Button, Col, Container, Modal } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import GameModeService from "../../../services/GameMode.services";
import "./styles.css";
import moment from "moment";
const PER_PAGE = 5;
const ListGameMode = () => {
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [listGameMode, setListGameMode] = useState([]);
  const [idDelete, setIdDelete] = useState();
  const [updatdedAt, setUpdatdedAt] = useState<number>(0);
  const [appliedAt, setAppliedAt] = useState<number>(0);
  const handleClose = () => setShow(false);

  const getGameModes = () => {
    setLoading(true);
    GameModeService.getGameModes().then((res: any) => {
      if (res?.data.length > 0) {
        setListGameMode(res?.data);
        setAppliedAt(+res?.appliedAt);
        setUpdatdedAt(+res?.updatdedAt);
      }
      setLoading(false);
    });
  };

  const handleDelete = () => {
    setLoading(true);
    GameModeService.deleteGameMode(idDelete)
      .then((res) => {
        if (res) {
          getGameModes();
          toast.success("Deleted Successfully!");
          setLoading(false);
          setPageNumber(0);
        }
      })
      .catch((err) => {
        toast.error("Deleted Failed!");
      });
    setShow(false);
  };


  const onApplyGameModesSettings = async () => {

    GameModeService.applyGameModes().then(result => {
      if (result?.data?.success) {
        setAppliedAt(+result?.data?.appliedAt);
        setUpdatdedAt(+result?.data?.updatdedAt);
        toast.success("Applied Successfully!");
      }
      else {
        toast.error("Applied Failed!");
      }

    }).catch(e => {
      toast.error("Something went wrong");
      console.log(e);
    });

  }

  useEffect(() => {
    getGameModes();
  }, []);
  const handlePageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  const totalPage = Math.ceil(listGameMode?.length / PER_PAGE);
  const currentPage = pageNumber * PER_PAGE;
  const displayData = listGameMode
    ?.slice(currentPage, currentPage + PER_PAGE)
    ?.map((item: any, index: any) => {
      return (
        <tr key={index}>
          <td>{index + PER_PAGE * pageNumber + 1}</td>
          <td className="hidden-name-table">{item.name}</td>
          <td className="hidden-name-table">{item.isRankedGame}</td>
          <td className="hidden-name-table">{item.supplyRequired}</td>
          <td className="hidden-name-table">{item.unlockLevel}</td>
          <td className="hidden-name-table">{`${item.isGuest}`}</td>
          {
            item.name === "Royal Game" ?
              (<>
                <td>
                  <button
                    type="button"
                    className="btn-custom btn-info mx-1 btn-primary"
                    onClick={() => Navigate(`/configRoyalGame`)}
                  >
                    <AiFillEdit />
                    View
                  </button>
                </td>
              </>)
              :
              (<>
                <td>
                  <button
                    type="button"
                    className="btn-custom btn-info mx-1 btn-primary"
                    onClick={() => Navigate(`/editGameMode/${item.gameModeId}`)}
                  >
                    <AiFillEdit />
                    Edit
                  </button>

                  <button
                    type="button"
                    className="btn-danger btn-custom"
                    onClick={() => {
                      setShow(true);
                      setIdDelete(item.gameModeId);
                    }}
                  >
                    <MdOutlineDelete className="duplicate-icon" fontSize="large" />
                    Delete
                  </button>
                </td>
              </>)
          }

        </tr>
      );
    });

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="list-time-bank pb-5">
          <Col>
            <BreadCrumbs childItem="List Game Modes" parentItem="Game Modes Management" parentLink="listGameMode" />
            <div className="card m-auto">
              <div className="portlet-title">
                <h3 className="portlet-title__text ">List Game Modes</h3>
              </div>
              <div className="table-data overflow-scroll">
                <table className="table table-bordered mt-5">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Ranked Name</th>
                      <th>Supply</th>
                      <th>UnLock Level </th>
                      <th>Guest</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{displayData}</tbody>
                </table>
              </div>
            </div>
          </Col>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPage}
            onPageChange={handlePageChange}
            activeClassName={"page-item active"}
            disabledClassName={"page-item disabled"}
            containerClassName={"pagination"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
          />

          {
            !appliedAt || appliedAt < updatdedAt && <div>
              <h4>
                Game modes has been changed. Apply now?
              </h4>
              <Button size="lg" variant="success" type="button" onClick={onApplyGameModesSettings}>
                Apply
              </Button>
            </div>
          }
          <div className="mt-3">
            <div>
              <small>Last Updated: {moment(updatdedAt).format("YYYY-MM-DD HH:mm:ss (UTCZ)")}</small>
            </div>
            <div>
              <small><b>Last Applied:</b> {moment(appliedAt).format("YYYY-MM-DD HH:mm:ss (UTCZ)")}</small>
            </div>
          </div>
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Do you want to delete ?</Modal.Body>
        <Modal.Footer>
          <button className="btn-custom btn-submit" onClick={handleClose}>
            Close
          </button>
          <button className="btn-custom btn-danger" onClick={handleDelete}>
            Remove
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default ListGameMode;
