import { Col, Modal, TabContainer, Accordion, Card, Button, Table } from "react-bootstrap";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import "./tournamentdetail.css"
import { Component, useEffect, useState } from "react";
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Loading from "../../../../components/loading/Loading";
import { Link, useParams } from "react-router-dom";
import { getDetailMiniTournament } from "../../../../services/MiniManagement.services";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { GrView } from "react-icons/gr";
const ACTIVES = ["STARTED"];
const INACTIVES = ["CLOSED", "SCHEDULE_STOPPED", "STOPPED"];
const TournamentDetail = () => {
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
    const { id } = useParams();
    const [tableData, setTableData] = useState<string[]>([])
    const [remainPlayer, setRemainPlayer] = useState<number>(0);
    const [status, setStatus] = useState<string>("");
    const [tournament, setTournament] = useState<any>();
    const [gameMatches, setGameMatches] = useState<any>();
    const [countTime, setCountTime] = useState<string>("0s");

    const toggleExpand = (index: number) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const { isLoading, refetch } = useQuery(["GET_TOURNAMENT_CONFIG"], () => getDetailMiniTournament(id ? id : ""), {
        onSuccess: (res) => {
            const { data, status } = res;
            if (data?.status) {
                setGameMatches(data?.gamematchesField)
                setTableData(data.rounds)
                setTournament(data?.tournament ? data?.tournament : []);
                setStatus(data?.tournament.status);
                setRemainPlayer(data?.remainingPlayers);
            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    });
    const handleCountRemainingPlayers = (players: any) => {
        let count = 0;
        players?.map((val: any) => {
            if (val.status == "won" || (val.status === "n/a" && status !== "SCHEDULE_STOPPED") || val.status === "playing") {
                count++
            }
        })
        return count
    }

    const onReload = () => {
        refetch();
    }

    const tick = () => {
        const currentTime = (new Date()).getTime();
        const startTime = new Date(tournament?.tournamentStartTime).getTime();

        const diffTime = currentTime - startTime;
        const d = Number(diffTime / 1000);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);

        const hDisplay = h > 0 ? `${h}h ` : "";
        const mDisplay = m > 0 ? `${m}m ` : "";
        const sDisplay = s > 0 ? `${s}s ` : "";

        const final = hDisplay + mDisplay + sDisplay;

        setCountTime(final);
    }
    useEffect(() => {

        var timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    }, [countTime]);

    const viewLogic = () => {
        if (ACTIVES.includes(status)) {
            return (
                <div className="col-lg-6 col-sm-6 col-xs-12 px-4 mb-5">
                    <div className="mb-4">
                        <h2>Current Tournament Running Since: {moment(tournament?.tournamentStartTime).format("DD/MM/YYYY HH:mm")} - {countTime}</h2>
                    </div>
                    <div className="mb-4">
                        <h2>Remaining Players: {remainPlayer} </h2>
                    </div>
                    <div className="mb-4">
                        <h2>Status: HAPPENING </h2>
                    </div>
                    <Button onClick={onReload} className=" action-icon" variant="outline-info">
                        Reload
                    </Button>
                </div>
            );
        }
        return (
            <div className="col-lg-6 col-sm-6 col-xs-12 px-4 mb-5">
                <div className="mb-4">
                    <h2>Start Time: {moment(tournament?.tournamentStartTime).format("DD/MM/YYYY HH:mm")} </h2>
                    <h2>End Time: {status === "SCHEDULE_STARTED" ? "WAITING" :
                        status === "FREEZED" ? status :
                            status === "STARTED" ? "HAPPENING" :
                                status == "SCHEDULE_STOPPED" ? "CLOSED (before happening)" : moment(tournament?.tournamentEndTime ? tournament.tournamentEndTime : tournament?.updatedAt).format("DD/MM/YYYY HH:mm")} </h2>
                </div>
                <div className="mb-4">
                    <h2>Remaining Players: {remainPlayer} </h2>
                </div>
                <div className="mb-4">
                    <h2>Status: {status} </h2>
                </div>
            </div>

        );
    }

    return (
        <>
            <TabContainer>
                {false ? (
                    <Loading />
                ) : (
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs
                                childItem=" Mini Tournament Report"
                                parentItem=" Mini Tournament Report"
                                parentLink="reportTournament" />
                            <div className="card m-auto custom onlyforthis">
                                <div className="portlet-title">
                                    <div className='title-wrapper'>
                                        <h3 className="page-title">Current Mini Tournament</h3>
                                    </div>
                                </div>
                                <div className="row px-4 my-4">
                                    <div className="col-lg-6 col-sm-6 col-xs-12 px-4 mb-5">
                                        <div className="mb-4">
                                            <h2>Total No. Of Players: {tournament?.numberOfPlayers}</h2>
                                        </div>
                                        <div className="mb-4">
                                            <h2>Tournament Id: {tournament?.tournamentId} </h2>
                                        </div>
                                    </div>
                                    {viewLogic()}
                                </div>
                                <List>
                                    {tableData && tableData?.map((item: any, index: any) => (
                                        <div key={index} >
                                            <div className="flex">
                                                <ListItem onClick={() => toggleExpand(index)} >
                                                    {expanded[index] ? <AddCircleOutlineIcon className="icon" /> : <RemoveCircleOutlineOutlinedIcon className="icon" />}
                                                    <ListItemText
                                                        primary={"Round " + (index + 1)}
                                                        primaryTypographyProps={{ style: { fontSize: '18px', marginLeft: '5px' } }}
                                                    />
                                                </ListItem>
                                            </div>
                                            <Collapse in={expanded[index]} style={{ marginLeft: '20px', fontWeight: '500' }} >
                                                <Table bordered hover className="custom-width-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Game ID</th>
                                                            <th>Player Type</th>
                                                            <th>User Name</th>
                                                            <th>Status</th>
                                                            <th>Remaining Players</th>
                                                            <th>View</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            item?.gamematchesField?.map((val: any, index: any) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{val.matchId}</td>
                                                                        <td>{val.players.map((v: any, i: any) => {
                                                                            return (
                                                                                <span>
                                                                                    {v.playerType} <br />
                                                                                </span>
                                                                            )
                                                                        })}
                                                                        </td>
                                                                        <td>{val.players.map((v: any, i: any) => {
                                                                            return (
                                                                                <span>
                                                                                    {v.username} <br />
                                                                                </span>
                                                                            )
                                                                        })}
                                                                        </td>
                                                                        <td>{val.players.map((v: any, i: any) => {
                                                                            return (
                                                                                <span>
                                                                                    {(v.status === "n/a" && status !== "SCHEDULE_STOPPED") ? "playing" : v.status} <br />
                                                                                </span>
                                                                            )
                                                                        })}
                                                                        </td>
                                                                        <td>{handleCountRemainingPlayers(val.players)}</td>
                                                                        <td>
                                                                            <Link to={`/detailTournament/${tournament?.tournamentId}/${val.matchId}`}>
                                                                                <Button className=" action-icon" variant="outline-info">
                                                                                    <GrView />
                                                                                </Button>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>

                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </Table>
                                            </Collapse>
                                        </div>
                                    ))}
                                </List>
                            </div>

                        </Col>
                    </div>
                )}
            </TabContainer>
        </>
    )
}
export default TournamentDetail