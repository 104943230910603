import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Col, TabContainer, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Loading from '../../../components/loading/Loading';
import BreadCrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import { useForm } from 'react-hook-form';
import "./minimanagement.css"
import { createMiniTournament, getListGameMode, getListMap, getMiniTournament, updateMiniTournament, updateStatusScheduling } from '../../../services/MiniManagement.services';
import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio } from '@mui/material';
interface ITournamentMap {
    name: string;
    mapId: string;
}
interface IMiniTournamentConfig {
    type: string;
    numberOfPlayers: string;
    schedule: any;
    xpReward: number[];
    coinReward: number[];
    gameMode: string;
    map: ITournamentMap;
}

interface IMiniTournamentFieldFormErrors {
    typeErrText?: string;
    numberOfPlayersErrText?: string;
    scheduleErrText?: string;
    coinRewardErrText?: string;
    gameModeErrText?: string;
    mapErrText?: string,
    startTimeErrText?: string,
}
interface IDayOfWeeks {
    id: number,
    name: string,
    isChecked: boolean
}
const dayOfWeeksPreset: Array<IDayOfWeeks> = [
    { id: 0, name: "Sun", isChecked: false },
    { id: 1, name: "Mon", isChecked: false },
    { id: 2, name: "Tue", isChecked: false },
    { id: 3, name: "Wed", isChecked: false },
    { id: 4, name: "Thu", isChecked: false },
    { id: 5, name: "Fri", isChecked: false },
    { id: 6, name: "Sat", isChecked: false },

];
interface IBeValidationError { message: string, field: string }
const MiniTournament: React.FC = () => {
    const [formValue, setFormValue] = useState<any>();
    const [startDate, setStartDate] = useState<any>();
    const [gameModes, setGameModes] = useState<any[]>([]);
    const [selectedGameMode, setSelectedGameMode] = useState<string>("");
    const [maps, setMaps] = useState<ITournamentMap[]>([]);
    const [selectedMap, setSelectedMap] = useState<string>("");
    const [check, setCheck] = useState<boolean>(false)
    const [status, setStatus] = useState<string>("");
    const [scheduleType, setScheduleType] = useState<string>("");
    const [scheduleFrequencyType, setScheduleFrequencyType] = useState<string>("");
    const [coinReward, setCoinReward] = useState<number[]>([]);

    const [beValidationErrors, setBeValidationErrors] = useState<Array<IBeValidationError>>([]);
    const handleStartDate = (value: any) => { setStartDate(value) };
    const [dayOfWeekState, setDayOfWeekState] = useState<Array<IDayOfWeeks>>(dayOfWeeksPreset);
    const [fieldFormErrors, setFieldFormErrors] = useState<IMiniTournamentFieldFormErrors>({});
    const { register, handleSubmit, setFocus, setError } = useForm<any>({});
    const [date, setDate] = useState(new Date());

    const handleChangeDayOfWeek = () => {
        return (
            <>
                <FormGroup>
                    <div className='flex flex-row p-3'>
                        {dayOfWeekState.map((dt, index) => {
                            return <FormControlLabel key={`dow_${index}`} className='input-custom' control={
                                <Checkbox
                                    checked={dt.isChecked}
                                    onChange={() => handleDayOfWeekChanged(dt)}
                                />} label={dt.name} />
                        })}
                    </div>
                </FormGroup>
            </>
        )
    }
    const handleDayOfWeekChanged = (dt: IDayOfWeeks) => {
        setDayOfWeekState(prevState => {
            const newState = prevState.map(obj => {
                if (obj.id === dt.id) {
                    return { ...obj, isChecked: !dt.isChecked };
                }
                return obj;
            });

            return newState;
        });
    }
    const handleReturnScheduling = (type: any, data: any) => {
        let schedule: any = {}

        if (type === "once") {
            schedule = {
                type: "once",
                time: {
                    hours: startDate ? +moment(startDate).format("HH") : formValue?.schedule?.time?.hours,
                    minutes: startDate ? +moment(startDate).format("mm") : formValue?.schedule?.time?.minutes,
                    timezone: moment().format("Z")
                }
            }
        } else if (type === "recurring" && scheduleType === "weekly") {
            const selectedDays = dayOfWeekState?.filter(ele => ele.isChecked)?.map(ele => ele.id);

            schedule = {
                type: "weekly",
                dayOfWeek: selectedDays.map((str: any) => Number(str)),
                time: {
                    hours: startDate ? +moment(startDate).format("HH") : formValue?.schedule?.time?.hours,
                    minutes: startDate ? +moment(startDate).format("mm") : formValue?.schedule?.time?.minutes,
                    timezone: moment().format("Z")
                }
            }
            setDayOfWeekState(prevState => {
                const newState = prevState.map(obj => {
                    return { ...obj, isChecked: selectedDays.includes(obj.id) };
                });

                return newState;
            });
        } else if (type === "recurring" && scheduleType === "hourly") {
            schedule = {
                type: "hourly",
                time: {
                    hours: startDate ? +moment(startDate).format("HH") : formValue?.schedule?.time?.hours,
                    minutes: startDate ? +moment(startDate).format("mm") : formValue?.schedule?.time?.minutes,
                    timezone: moment().format("Z")
                }
            }
        } else {
            schedule = {
                type: "daily",
                time: {
                    hours: startDate ? +moment(startDate).format("HH") : formValue?.schedule?.time?.hours,
                    minutes: startDate ? +moment(startDate).format("mm") : formValue?.schedule?.time?.minutes,
                    timezone: moment().format("Z")
                }
            }
        }
        return schedule
    }

    const onSubmit = (data: any, e: any) => {
        console.log("🚀 ~ file: MiniManagement.tsx:122 ~ onSubmit ~ data:", data);
        const payload: IMiniTournamentConfig = {
            type: scheduleFrequencyType,
            numberOfPlayers: +data?.numberOfPlayers ? +data?.numberOfPlayers : formValue?.numberOfPlayers,
            schedule: handleReturnScheduling(scheduleFrequencyType, data),
            xpReward: [300, 200, 100],
            coinReward: [
                +data?.coinReward1 ? +data?.coinReward1 : coinReward[0],
                +data?.coinReward2 ? +data?.coinReward2 : coinReward[1],
                +data?.coinReward3 ? +data?.coinReward3 : coinReward[2],
            ],
            gameMode: selectedGameMode ? selectedGameMode : formValue?.gameMode,
            map: {
                name: selectedMap ? maps?.find(ele => ele?.mapId === selectedMap)?.name : formValue?.map?.name,
                mapId: selectedMap ? selectedMap : formValue?.map?.mapId
            }
        }
        return check ? UpdateMiniManagement.mutateAsync(payload) : CreateMiniManagement.mutateAsync(payload);
    };
    const onHandleResetFieldFrom = () => {
        setFieldFormErrors({
            typeErrText: "",
            numberOfPlayersErrText: "",
            scheduleErrText: "",
            coinRewardErrText: "",
            gameModeErrText: "",
            mapErrText: "",
        });
    }
    const handleStart = async (data: any, e: any) => {
        onHandleResetFieldFrom();
        const result = await onSubmit(data, e);

        // for validations errors
        const rawError = (result as any).errors;
        if (rawError) {
            if (Array.isArray(rawError)) {
                const errors = Array.from<IBeValidationError>(rawError);
                setBeValidationErrors(errors);
                let errorText = "";
                for (let i = 0; i < errors?.length; ++i) {
                    errorText += `${errors[i].message}. `
                }
                return toast.error(errorText);
            }
        }
        UpdateStatusMutation.mutate({
            "status": "start"
        })
    }
    const handleStop = () => {
        UpdateStatusMutation.mutate({
            "status": "stop"
        })
    }
    const { isLoading, refetch } = useQuery(["GET_TOURNAMENT_CONFIG"], () => getMiniTournament(), {
        onSuccess: (res) => {
            const { data } = res;
            if (data?.status) {
                setFormValue(data);
                setStatus(data?.status)
                setCheck(true)
                onHandleResetFieldFrom();
                setScheduleType(data?.schedule?.type);
                setScheduleFrequencyType(data?.type);
                setSelectedGameMode(data?.gameMode);
                setSelectedMap(data?.map?.mapId);
                if (data?.coinReward && Array.isArray(data?.coinReward)) {
                    setCoinReward(data?.coinReward);
                }
                // const selected dayofweeks 
                if (data?.schedule?.type === "weekly") {
                    const dow = Array.isArray(data?.schedule?.dayOfWeek) ? Array.from<number>(data?.schedule?.dayOfWeek) : [];
                    setDayOfWeekState(prevState => {
                        const newState = prevState.map(obj => {
                            return { ...obj, isChecked: dow.includes(obj.id) };
                        });
                        return newState;
                    });
                }

            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    });


    useQuery(["GET_GAME_MODE"], () => getListGameMode(), {
        onSuccess: (res) => {
            const { data } = res;
            setGameModes(data)
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    })
    useQuery(["GET_LIST_MAP"], () => getListMap(), {
        onSuccess: (res) => {
            const { data } = res;
            setMaps(data)
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    })
    const UpdateMiniManagement = useMutation((data: any) => updateMiniTournament(data, formValue?._id ? formValue?._id : ""), {
        onSuccess: (res) => {
            console.log("UpdateMiniManagement", res);
        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });
    const CreateMiniManagement = useMutation((data: any) => createMiniTournament(data), {
        onSuccess: (res) => {
            console.log("CreateMiniManagement", res);

        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });
    const UpdateStatusMutation = useMutation((data: any) => updateStatusScheduling(data), {
        onSuccess: (res) => {
            // 
            if (res?.data?.code) {
                if (["UpdateTournamentStatus"].includes(res?.data?.code)) {
                    setFocus('startTime');
                    setFieldFormErrors(prevState => ({
                        ...prevState,
                        ...{
                            startTimeErrText: res?.data?.message
                        }
                    }));
                }
                return toast.error(res?.data?.message);
            }
            toast.success("Update Status Successfully");
            if (res?.data?.tournament?.status) {
                setStatus(res.data.tournament.status)
            }
            refetch();
        },
        onError: (error: any) => {
            toast.error(error.response.data.message)
        },
    });

    const onHandleShowValidationOnForm = (ele: IBeValidationError) => {
        const fieldName = ele?.field?.replace('body-', '');
        switch (fieldName) {
            case "type": {
                setError(fieldName, { message: ele?.message });
                setFieldFormErrors(prevState => ({
                    ...prevState,
                    ...{
                        typeErrText: ele?.message
                    }
                }));
                break;
            }
            case "numberOfPlayers": {
                setFocus(fieldName);
                setFieldFormErrors(prevState => ({
                    ...prevState,
                    ...{
                        numberOfPlayersErrText: ele?.message
                    }
                }));
                break;
            }
            case "coinReward": {
                setFocus(fieldName);
                setFieldFormErrors(prevState => ({
                    ...prevState,
                    ...{
                        coinRewardErrText: ele?.message
                    }
                }));
                break;
            }
            case "gameMode": {
                setFocus(fieldName);
                setFieldFormErrors(prevState => ({
                    ...prevState,
                    ...{
                        mapErrText: ele?.message
                    }
                }));
                break;
            }
            case "mapId": {
                setFocus(fieldName);
                setFieldFormErrors(prevState => ({
                    ...prevState,
                    ...{
                        mapErrText: ele?.message
                    }
                }));
                break;
            }
            default: {
                break;
            }
        }
    }
    const tick = () => {
        setDate(new Date());
    }
    const onSetSchduleType = (event: any) => {
        setScheduleType(event?.target.value)
    }
    const onFrequencyChange = (event: any) => {
        setScheduleFrequencyType(event?.target.value)
    }
    const onGameModeSelectionChanged = (event: any) => {
        setSelectedGameMode(event?.target.value)
    }
    const onMapSelectionChanged = (event: any) => {
        setSelectedMap(event?.target.value)
    }
    useEffect(() => {
        beValidationErrors?.filter(ele => {
            onHandleShowValidationOnForm(ele);
            return ele;
        });
        var timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    }, [setFocus, beValidationErrors]);
    return (
        <>
            <TabContainer>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs
                                childItem="Config"
                                parentItem="Mini Tournament Management"
                                parentLink="minTournament" />
                            <div className="card m-auto custom onlyforthis">
                                <div className="portlet-title">
                                    <div className='title-wrapper'>
                                        <h3 className="page-title">Mini Tournament Config</h3>
                                    </div>
                                </div>

                                <div className="form-search">
                                    {/* Uh Oh MM for month mm for minutes */}
                                    <div className='flex justify-content-center mb-3'>
                                        <h3>Current Local Time: {moment(date).format("HH:mm:ss (UTCZ)")}</h3>

                                    </div>
                                    <div className='flex justify-content-center mb-3'>
                                        {status === "SCHEDULE_STARTED" &&
                                            <h3>Start Time: {moment(formValue?.tournamentStartTime).format("HH:mm:ss (UTCZ)")}</h3>
                                        }
                                    </div>
                                    <form onSubmit={handleSubmit(handleStart)} >
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <label className="label-bold">
                                                    Status
                                                </label>
                                                <input
                                                    id="name"
                                                    type="text"
                                                    placeholder="Status"
                                                    className="form-control"
                                                    disabled
                                                    value={status}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <label className="label-bold">
                                                    No. of players
                                                </label>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="No. Of Player"
                                                    className="form-control"
                                                    {...register("numberOfPlayers")}
                                                    defaultValue={formValue?.numberOfPlayers}
                                                />
                                                {fieldFormErrors?.numberOfPlayersErrText && <div className='error'>{fieldFormErrors?.numberOfPlayersErrText}</div>}

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4" {...register("gameMode")}>
                                                <label className="label-bold">
                                                    Game mode
                                                </label>
                                                <Form.Group controlId="exampleForm.SelectCustom">
                                                    <Form.Control as="select" onChange={onGameModeSelectionChanged.bind(this)} value={selectedGameMode}>
                                                        {gameModes?.map((g, index) => {
                                                            return (
                                                                <option key={`gmode_${index}`} value={g.gameModeId}>{g.name}</option>
                                                            )
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>
                                                {fieldFormErrors?.gameModeErrText && <div className='error'>{fieldFormErrors?.gameModeErrText}</div>}

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4" {...register("type")}>
                                                <label className="label-bold">
                                                    Frequency
                                                </label>
                                                <Form.Group controlId="exampleForm.SelectCustom">
                                                    <Form.Control onChange={onFrequencyChange.bind(this)} as="select" value={scheduleFrequencyType} >
                                                        <option value="recurring">Recurring</option>
                                                        <option value="once">Only Once</option>
                                                    </Form.Control>
                                                </Form.Group>
                                                {fieldFormErrors?.typeErrText && <div className='error'>{fieldFormErrors?.typeErrText}</div>}

                                            </div>
                                            <div className="col-12">

                                                {scheduleFrequencyType === "recurring" && <div className='col-12 col-md-6'>
                                                    <div>
                                                        <label className="label-bold">
                                                            Schedule Type
                                                        </label>
                                                        <div className='pb-3'>
                                                            <FormControl>
                                                                <div onChange={onSetSchduleType.bind(this)}>
                                                                    <Radio className='input-custom' checked={scheduleType === "hourly"} value="hourly" inputProps={{ 'aria-label': 'Hourly' }} />Hourly
                                                                    <Radio className='input-custom' checked={scheduleType === "daily"} value="daily" inputProps={{ 'aria-label': 'Daily' }} />Daily
                                                                    <Radio className='input-custom' checked={scheduleType === "weekly"} value="weekly" inputProps={{ 'aria-label': 'Weekly' }} />Weekly
                                                                </div>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                    {scheduleType === "weekly" ? handleChangeDayOfWeek() : <></>}
                                                </div>}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <label className="label-bold">
                                                    Schedule Start Time
                                                </label>
                                                <DatePicker
                                                    {...register('startTime')}
                                                    autoComplete="off"
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    dateFormat="HH:mm"
                                                    timeFormat="HH:mm"
                                                    placeholderText="Start Time"
                                                    name="startTime"
                                                    className="form-control"
                                                    selected={startDate}
                                                    timeIntervals={1}
                                                    onChange={(e) => handleStartDate(e)}
                                                    value={startDate ? startDate : formValue?.schedule?.time
                                                        ? moment()
                                                            .utcOffset(formValue.schedule.time.timezone)
                                                            .set({
                                                                hours: formValue.schedule.time.hours,
                                                                minutes: formValue.schedule.time.minutes
                                                            })
                                                            .format("HH:mm")
                                                        : ""}
                                                />
                                                {fieldFormErrors?.startTimeErrText && <div className='error'>{fieldFormErrors?.startTimeErrText}</div>}

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4" {...register("mapId")}>
                                                <label className="label-bold">
                                                    Map
                                                </label>
                                                <Form.Group controlId="exampleForm.SelectCustom">
                                                    <Form.Control as="select" onChange={onMapSelectionChanged.bind(this)} value={selectedMap} >
                                                        {maps?.map((g, index) => {
                                                            return (
                                                                <option key={`tmap_${index}`} value={g.mapId} >{g.name}</option>
                                                            )
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>
                                                {fieldFormErrors?.mapErrText && <div className='error'>{fieldFormErrors?.mapErrText}</div>}

                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <div className="row">
                                                    <div {...register(`coinReward`)} className="flex">
                                                        <div className="col-12 col-md-2 col-lg-2 mb-4">
                                                            <label className='label-bold'>Coin Reward</label>
                                                        </div>
                                                        <div className="col-12 col-md-3 col-lg-3 mb-4">
                                                            <div>
                                                                <label className='label-bold'>1st place</label>
                                                            </div>
                                                            <input
                                                                required
                                                                type="number"
                                                                placeholder={`1st place`}
                                                                className="form-control"
                                                                {...register(`coinReward1`)}
                                                                defaultValue={coinReward[0]}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-md-3 col-lg-3 mb-4">
                                                            <div>
                                                                <label className='label-bold'>2nd place</label>
                                                            </div>
                                                            <input
                                                                required
                                                                type="number"
                                                                placeholder={`2nd place`}
                                                                className="form-control"
                                                                {...register(`coinReward2`)}
                                                                defaultValue={coinReward[1]}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-md-3 col-lg-3 mb-4">
                                                            <div>
                                                                <label className='label-bold'>3rd place</label>
                                                            </div>
                                                            <input
                                                                required
                                                                type="number"
                                                                placeholder={`3rd place`}
                                                                className="form-control"
                                                                {...register(`coinReward3`)}
                                                                defaultValue={coinReward[2]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {fieldFormErrors?.coinRewardErrText && <div className='error flex justify-content-center'>{fieldFormErrors?.coinRewardErrText}</div>}
                                            </div>
                                        </div>
                                        <div className="button-group center-item mt-4">
                                            <Button size="lg" variant="primary" onClick={() => refetch()}>
                                                Reload Config
                                            </Button>
                                        </div>

                                        <div className="button-group flex mt-4">
                                            <Button size="lg" variant="success" type="submit" disabled={status === "SCHEDULE_STARTED"}>
                                                Start Scheduling
                                            </Button>
                                            <Button size="lg" variant="danger" type="button" disabled={status === "SCHEDULE_STOPPED"} onClick={handleStop} >
                                                Stop Scheduling
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col >
                    </div >
                )}
            </TabContainer >
        </>

    );
};

export default MiniTournament;