import React, { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";
import "./styles.css";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import GamePlayServices from "../../../services/GamePlay.services";
import { toast } from "react-toastify";
import moment from "moment";

const ViewGamePlay = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  // fetchAPI
  const fetchAPI = () => {
    GamePlayServices.getGamePlay(id)
      .then((res: any) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Not Found");
      });
  };
  useEffect(() => {
    fetchAPI();
  }, []);

  
  // const handleArrangeRank = (arr: any) => {
  //   let data = arr.slice();
  //   let outputRank: any = [];
  //   let max = 0;
  //   let pos = 0;
  //   let prevMax = 0;
  //   let count = 1;

  //   for (let i = 0; i < data.length; i++) {
  //     max = Math.max(...data.map(({ totalPoint }: any) => totalPoint));
  //     pos = data.findIndex(({ totalPoint }: any) => totalPoint === max);

  //     outputRank[pos] = count + checkOderRank(count);
  //     data.splice(pos, 1, { totalPoint: null });
  //     if (max === prevMax) {
  //       count -= 1;

  //       outputRank[pos] = count + checkOderRank(count);
  //     }
  //     prevMax = max;
  //     count++;
  //   }
  //   return (
  //     <>
  //       {outputRank.map((i: any, index: number) => (
  //         <div key={index}>{i}</div>
  //       ))}
  //     </>
  //   );
  // };

  const checkOderRank = (count: number) => {
    if (count === 1 || count % 10 === 1) {
      return " st Rank";
    } else if (count === 2) {
      return " nd Rank";
    } else if (count === 3) {
      return " rd Rank";
    } else {
      return " th Rank";
    }
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="create-time-bank  pb-5">
          <Col>
            <BreadCrumbs
              childItem="View Game Play"
              parentLink="listGamePlay"
              parentItem="List Game Play"
            />
            <div className="card m-auto text-center">
              <h3 className="page-title text-center">View Game Play</h3>
              <div className="body-form">
                <form>
                  <div className="table overflow-auto">
                    <table className="table table-bordered mt-5">
                      <tbody>
                        <tr>
                          <th>Game Id</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Game Type</th>
                        </tr>
                        <tr>
                          <td className="hidden-name-table">
                            {data?.gameSessionId}
                          </td>
                          <td className="hidden-name-table">
                            {moment(data?.gameStartedDate).format(
                              "DD-MM-yy HH:mm"
                            )}
                          </td>
                          <td className="hidden-name-table">
                            {moment(data?.gameCompletedDate).format(
                              "DD-MM-yy HH:mm"
                            )}
                          </td>
                          <td className="hidden-name-table">
                            {data?.gameType}
                          </td>
                        </tr>
                        <tr>
                          <th>Players Username</th>
                          <th>Players Type</th>
                          <th>Players Rank</th>
                          <th>Player XP Gained</th>
                        </tr>
                        <tr>
                          <td className="hidden-name-table">
                            {data?.gameOverData?.map(
                              ({ userName }: any, index: number) => (
                                <div key={index}>{userName}</div>
                              )
                            )}
                          </td>
                          <td className="hidden-name-table">
                            {data?.gameOverData?.map(
                              ({ playerId }: any, index: number) => (
                                <div key={index}>
                                  {playerId?.includes("plb_") ? "Bot" : "Player"}
                                </div>
                              )
                            )}
                          </td>
                          <td className="hidden-name-table">
                            {data?.gameOverData?.map(({ position }: any) => (
                              <div key={position}>
                                {position}
                                {checkOderRank(position)}
                              </div>
                            ))}
                          </td>
                          <td className="hidden-name-table">
                            {data?.gameOverData?.map(
                              ({ totalXp }: any, index: number) => (
                                <div key={index}>{totalXp} XP</div>
                              )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
                {data?.gameHistory?.expansion?.length > 0 && (
                  <div className="row my-3 p-5">
                    <div className="note note-info">
                      <h3 className="text-start">Expansion Round</h3>
                    </div>
                    {data?.gameHistory?.expansion.map(
                      ({ guessQuestion, winners, guessAnswer }: any, round: number) => (
                        <div className="d-flex flex-column gap-4 my-3">
                          <h4 className="text-start fw-bold">
                            Question {round + 1}
                          </h4>
                          <div className="fs-3 text-start">{guessQuestion} -  Answer: <span className="fw-bold">{guessAnswer}</span></div>
                          <div className="flex justify-content-start gap-5">
                            {winners?.map(
                              ({ playerId, answerSubmitted }: any) => {

                                const playerData = data?.players?.find((player:any) => player.playerId == playerId);

                                 return (
                                  <div className="fs-4 ">
                                    {playerData ? playerData.username : playerId} -
                                    <span className="fw-bold">
                                      {answerSubmitted}
                                    </span>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
                {data?.gameHistory?.battle?.mcqQuestion?.length > 0 && (
                  <div className="row my-3 p-5">
                    <div className="note note-info">
                      <h3 className="text-start">Battle Round</h3>
                    </div>
                    <h4>MCQ Questions:</h4>
                    {data?.gameHistory?.battle.mcqQuestion.map(
                      (
                        { mcqQuestion, answers, winners, correctAnswer }: any,
                        round: number
                      ) => (
                        <div className="d-flex flex-column gap-4 my-3">
                          <h4 className="text-start fw-bold">
                            Question {round + 1}
                          </h4>
                          <div className="fs-3 text-start">{mcqQuestion} -  Answer: <span className="fw-bold">{correctAnswer}</span></div>
                          <div className="d-flex fs-4 gap-4 flex-wrap">
                            {answers.map((item: string, index: number) => (
                              <div>
                                <span className="fw-bold">
                                  {String.fromCharCode(index + 65) + ". "}
                                </span>
                                {item}
                              </div>
                            ))}
                          </div>
                          <div className="flex justify-content-start gap-4 flex-wrap">
                            {winners?.map(({ playerId, MCQ, MCQIndex }: any) => {
                              const playerData = data?.players?.find((player:any) => player.playerId == playerId);
                              return (
                              <div className="fs-4">
                                {playerData ? playerData.username : playerId} -
                                <span className="fw-bold">
                                  {
                                    MCQIndex == "N/A" ? "N/A" : answers[MCQIndex-1]
                                  }
                                  {
                                  /*" " +
                                    String.fromCharCode(
                                      answers.indexOf(MCQ) + 65
                                    )*/
                                    }
                                </span>
                              </div>
                              )
                            }
                            )}
                          </div>
                        </div>
                      )
                    )}
                    <hr/>
                    <h4>Guess Questions:</h4>
                    {data?.gameHistory?.battle.guessQuestion.map(
                      (
                        { guessQuestion, correctAnswer, winners }: any,
                        round: number
                      ) => (
                        <div className="d-flex flex-column gap-4 my-3">
                          <h4 className="text-start fw-bold">
                            Question {round + 1}
                          </h4>
                          <div className="fs-3 text-start">{guessQuestion} -  Answer: <span className="fw-bold">{correctAnswer}</span></div>
                          <div className="flex justify-content-start gap-5">
                            {winners?.map(
                              ({ playerId, answerSubmitted }: any) => {

                                const playerData = data?.players?.find((player:any) => player.playerId == playerId);

                                 return (
                                  <div className="fs-4 ">
                                    {playerData ? playerData.username : playerId} -
                                    <span className="fw-bold">
                                      {answerSubmitted}
                                    </span>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
              <Link to="/listGamePlay">
                <button
                  type="button"
                  className="btn-custom btn-secondary mx-2 mb-5 my-5"
                >
                  Back
                </button>
              </Link>
            </div>
          </Col>
        </div>
      )}
    </Container>
  );
};

export default ViewGamePlay;
