import Config from "../config/config";
import AxiosInstance from "../utils/axios/AxiosInstance";

const url = "/games";

const getGamePlay = (id: any) => {
  return AxiosInstance.get(Config.apiUrl + url + `/${id}`);
};

const getListGamePlayer = (data?: any) => {
  const { username, sorting, desc_asc, page, startDate, endDate, gameType } =
    data;
  return AxiosInstance.get(
    Config.apiUrl +
      url +
      `?username=${username}&sortBy=${sorting}&sortOrder=${desc_asc}&skip=${page}&limit=10&startDate=${startDate}&endDate=${endDate}&gameType=${gameType}`
  );
};

const GamePlayServices = {
  getListGamePlayer,
  getGamePlay,
};

export default GamePlayServices;
