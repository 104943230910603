import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AuthLayout from "./layout/AuthLayout/AuthLayout";
import DashboardLayout from "./layout/DashboardLayout/DashboardLayout";
import CreateGameMode from "./pages/GameModesManagement/createGameMode/CreateGameMode";
import EditGameMode from "./pages/GameModesManagement/editGameMode/EditGameMode";
import ListGameMode from "./pages/GameModesManagement/listGameMode/ListGameMode";
import ListPlayers from "./pages/PlayerManagement/listPlayers/ListPlayers";
import Login from "./pages/login/Login";
import ViewPlayerProfile from "./pages/PlayerManagement/viewPlayerProfile/ViewPlayeProfile";
import ListGamePlay from "./pages/GamePlayReport/listGamePlay/ListGamePlay";
import ViewGamePlay from "./pages/GamePlayReport/viewGamePlay/ViewGamePlay";
import TournamentReport from "./pages/MiniTournament/Report/TournamentReport";
import TournamentDetail from "./pages/MiniTournament/Report/TourmamentDetail/TournamentDetail";
import MiniManagement from "./pages/MiniTournament/Management/MiniManagement";
import RoyalGame from "./pages/RoyalGame";
import RoyalGameReport from "./pages/RoyalGame/RoyalGameReport";
import RoyalGameReportDetail from "./pages/RoyalGame/RoyalGameReportDetail";

import QuestionDetail from "./pages/MiniTournament/Report/Question";
function App() {
  const token = localStorage.getItem("access_token");
  return (
    <BrowserRouter>
      <Routes>
        {/* LAYOUT DASHBOARD */}
        {/* <Route path="*" element={token ? <DashboardLayout /> : <Login />} /> */}
        {token ? (
          <Route path="/" element={<DashboardLayout />}>
            <Route path="*" element={<Navigate to="/" />} />

            <Route path="/createGameMode" element={<CreateGameMode />} />

            <Route path="/editGameMode/:id" element={<EditGameMode />} />
            <Route path="/listGameMode" element={<ListGameMode />} />

            <Route path="/listPlayers" element={<ListPlayers />} />
            <Route
              path="/viewPlayerProfile/:id"
              element={<ViewPlayerProfile />}
            />

            {/* Game Play Report */}
            <Route path="/listGamePlay" element={<ListGamePlay />} />
            <Route path="/viewGamePlay/:id" element={<ViewGamePlay />} />

            {/* Tournamnt Management */}

            <Route
              path="/minTournament"
              element={<MiniManagement />}
            />
            {/* TOURNAMENT REPORT */}
            <Route path="/reportTournament" element={<TournamentReport />} />
            <Route path="/detailTournament/:id" element={<TournamentDetail />} />
            <Route path="/detailTournament/:tournId/:matchId" element={<QuestionDetail />} />

            {/* Royal Game Config configRoyalGame */}
            <Route path="/configRoyalGame" element={<RoyalGame />} />
            {/* Royal Game Report */}
            <Route path="/reportRoyalGame" element={<RoyalGameReport />} />
            <Route path="/reportRoyalGame/details/:gameId" element={<RoyalGameReportDetail />} />
          </Route>
        ) : (
          <Route path="/" element={<AuthLayout />}>
            <Route path="*" element={<Navigate to="/" />} />
            <Route index element={<Login />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
