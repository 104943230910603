import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import GameModeService from "../../../services/GameMode.services";
import { createGameModeSchema } from "../../../utils/schemaValidation";
import "./styles.css";
type FormData = {
  name: string;
  isRankedGame: string;
  supplyRequired: number;
  unlockLevel: number;
  isGuest: boolean;
};

const defaultValue = {
  name: "",
  isRankedGame: "",
  supplyRequired: 0,
  unlockLevel: 0,
  isGuest: false,
};

const CreateGameMode = () => {
  const [loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<FormData>({
    resolver: yupResolver(createGameModeSchema),
    defaultValues: defaultValue,
  });

  console.log(isSubmitSuccessful);

  const isRanked = watch().isRankedGame === "Yes" ? true : false;

  const onSubmit = (data: FormData) => {
    console.log("vo day");

    GameModeService.createGameMode(data).then(
      (res: any) => {
        if (res._id) {
          toast.success("Created Game Mode Successfully");
          Navigate("/listGameMode");
        }
      },
      (err) => {
        toast.error("Created Game Mode Fail");
      }
    );
  };
  useEffect(() => {
    if (isRanked) setValue("supplyRequired", 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRanked]);
  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="create-time-bank pb-5">
          <Col>
            <BreadCrumbs childItem="Create Game Mode" parentItem="Game Modes Management" parentLink="listGameMode" />
            <div className="card m-auto text-center">
              <h3 className="page-title text-center">Create Game Mode</h3>
              <div className="body-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="gameModeName"
                          className="form-control-label"
                        >
                          Game Mode Name
                        </label>
                        <input
                          id="gameModeName"
                          type="text"
                          placeholder="Game Mode Name"
                          className="form-control"
                          {...register("name")}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => (
                            <p className="text-danger fs-3 mt-2">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label
                          htmlFor="isRankedGame"
                          className="form-control-label"
                        >
                          Is Ranked Game
                        </label>
                        <select
                          id="isRankedGame"
                          className="form-control"
                          {...register("isRankedGame")}
                        >
                          <option value="" hidden>
                            Choose options
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name="isRankedGame"
                          render={({ message }) => (
                            <p className="text-danger fs-3 mt-2">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    {isRanked && (
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="supplyRequired"
                            className="form-control-label"
                          >
                            Supplies Required
                          </label>
                          <input
                            id="supplyRequired"
                            type="number"
                            className="form-control"
                            {...register("supplyRequired", {
                              valueAsNumber: true,
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="supplyRequired"
                            render={({ message }) => (
                              <p className="text-danger fs-3 mt-2">{message}</p>
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label
                          htmlFor="unlockLevel"
                          className="form-control-label"
                        >
                          Unlock Level
                        </label>
                        <input
                          id="unlockLevel"
                          type="number"
                          className="form-control"
                          {...register("unlockLevel", {
                            valueAsNumber: true,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="unlockLevel"
                          render={({ message }) => (
                            <p className="text-danger fs-3 mt-2">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label htmlFor="isGuest" className="form-control-label">
                          Is Guest
                        </label>
                        <select
                          id="isGuest"
                          className="form-control"
                          {...register("isGuest")}
                        >
                          <option hidden>Choose options</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name="isGuest"
                          render={({ message }) => (
                            <p className="text-danger fs-3 mt-2">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn_group mt-5 d-flex justify-content-center align-items-center pb-5">
                    <button
                      type="button"
                      className="btn-custom btn-submit mx-2"
                      onClick={() => Navigate("/listGameMode")}
                    >
                      Back
                    </button>
                    {isSubmitSuccessful ? (
                      <span className="loader"></span>
                    ) : (
                      <button type="submit" className="btn-custom btn-submit">
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </div>
      )}
    </Container>
  );
};
export default CreateGameMode;
