import Config from "../config/config";
import AxiosInstance from "../utils/axios/AxiosInstance";

const createGameMode = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "gamemodes", data);
};

const getGameModes = () => {
  return AxiosInstance.get(Config.apiUrl + "gamemodes");
};

const getGameMode = (id: string | undefined) => {
  return AxiosInstance.get(Config.apiUrl + "gamemodes/" + id);
};

const updateGameMode = (data: any, id: string | undefined) => {
  return AxiosInstance.put(Config.apiUrl + "gamemodes/" + id, data);
};

const deleteGameMode = (id: string | undefined) => {
  return AxiosInstance.delete(Config.apiUrl + "gamemodes/" + id);
};

const applyGameModes = () => {
  return AxiosInstance.get(Config.apiUrl + "apply/gamemodes");
};

const getRoyalConfig = () => {
  return AxiosInstance.get(Config.apiUrl + "royalconfig");
};


const GameModeService = {
  createGameMode,
  getGameModes,
  getGameMode,
  updateGameMode,
  deleteGameMode,
  applyGameModes,
  getRoyalConfig
};

export default GameModeService;
