import { useEffect, useState } from "react";
import { Col, Container, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AiFillEdit } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { MdOutlineDelete, MdOutlinePreview } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.css";
import GamePlayServices from "../../../services/GamePlay.services";
import GameModeService from "../../../services/GameMode.services";
import moment from "moment";
const initSearchData = {
  username: "",
  sorting: "",
  desc_asc: "",
  page: 0,
  gameType: "",
  startDate: "",
  endDate: "",
};
const PER_PAGE = 10;
const ListGamePlay = () => {
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [formContent, setFormContent] = useState<any>([]);
  const [listData, setListData] = useState([]);
  const [dataSearch, setDataSearch] = useState(initSearchData);
  const [sizeData, setSizeData] = useState(0);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [listGameMode, setListGameMode] = useState<any[]>([]);

  const getGameModes = () => {
    setLoading(true);
    GameModeService.getGameModes().then((res: any) => {
      const gameModes: any[] = Array.isArray(res?.data) ? Array.from(res.data) : [];
      if (gameModes.length > 0) {
        // only quick & clash will be filtered    
        setListGameMode(gameModes?.filter(ele => ["Quick", "Clash"].includes(ele?.name)));
      }
      setLoading(false);
    });
  };

  //fecthApi
  const fetchApi = (data: any) => {
    GamePlayServices.getListGamePlayer(data).then(
      (res: any) => {
        const { gameSessions, total, errors } = res;
        setListData(gameSessions);
        setSizeData(total);
        console.log("🚀 ~ file: ListGamePlay.tsx:61 ~ fetchApi ~ errors:", errors)
        if (errors?.length > 0) {
          toast.error(errors[0].message);
        } else if (total === 0) {
          toast.error("No data found");
        }
      },
      (err) => {
        toast.error("Not Found");
      }
    );
  };
  useEffect(() => {
    getGameModes();
    fetchApi(dataSearch);
  }, []);

  const handlePageChange = ({ selected }: any) => {
    setPageNumber(selected);
    setDataSearch({ ...dataSearch, page: selected });
    fetchApi({ ...dataSearch, page: selected });
  };

  // csv
  // const handleCSV = () => {
  //   let content = listData?.map((item: any, index: number) => {
  //     return {
  //       ["S.No"]: index,
  //       ["Username"]: item.username,
  //       ["Created At"]: item.createdAt,
  //       ["Country Name"]: item.countryName,
  //       ["Email"]: item.emailId,
  //       ["Login Type"]: item.isGuest,
  //       ["Player Unique Id"]: item._id,
  //       ["Facebook Id"]: item.facebookId,
  //       ["Total Coins"]: item.coins,
  //       ["Player XP Level"]: item.currentLevel,
  //       ["Total XP"]: item.playerStats.xpPoints,
  //       ["No. Of Supplies"]: item.supplies,
  //     };
  //   });
  //   setFormContent(content);
  // };
  // const csvReport = {
  //   data: formContent,
  //   header: [
  //     "S.No",
  //     "Username",
  //     "Created At",
  //     "Country Name",
  //     "Email",
  //     "Login Type",
  //     "Player Unique Id",
  //     "Facebook Id",
  //     "Total Coins",
  //     "Player XP Level",
  //     "Total XP",
  //     "No. Of Supplies",
  //   ],
  //   filename: "ListPlayers.csv",
  // };

  //search
  const handleSearch = () => {
    if (!startDate && !endDate) {
      toast.error("Please ensure you pick two dates.");
      return;
    }

    const dataPost = {
      ...dataSearch,
      page: 0,
      startDate: moment(startDate).format("yy-MM-DD HH:mm"),
      endDate: moment(endDate).format("yy-MM-DD HH:mm"),
    };
    setPageNumber(0);
    setDataSearch(dataPost);
    fetchApi(dataPost);
  };

  const handelSeachOnChange = (e: any) => {
    const { name, value } = e.target;
    setDataSearch({ ...dataSearch, [name]: value.trim() });
  };

  const handleStartDate = (value: any) => {
    setStartDate(value);
  };

  const handleEndDate = (value: any) => {
    setEndDate(value);
  };

  // const handleArrangeRank = (arr: any) => {
  //   let data = arr.slice();
  //   let outputRank: any = [];
  //   let max = 0;
  //   let pos = 0;
  //   let prevMax = 0;
  //   let count = 1;

  //   for (let i = 0; i < data.length; i++) {
  //     max = Math.max(...data.map(({ totalPoint }: any) => totalPoint));
  //     pos = data.findIndex(({ totalPoint }: any) => totalPoint === max);

  //     outputRank[pos] = count + checkOderRank(count);
  //     data.splice(pos, 1, { totalPoint: null });
  //     if (max === prevMax) {
  //       count -= 1;

  //       outputRank[pos] = count + checkOderRank(count);
  //     }
  //     prevMax = max;
  //     count++;
  //   }
  //   return (
  //     <>
  //       {outputRank.map((i: any, index: number) => (
  //         <div key={index}>{i}</div>
  //       ))}
  //     </>
  //   );
  // };

  const checkOderRank = (count: number) => {
    if (count === 1 || count % 10 === 1) {
      return " st Rank";
    } else if (count === 2) {
      return " nd Rank";
    } else if (count === 3) {
      return " rd Rank";
    } else {
      return " th Rank";
    }
  };

  const totalPage = Math.ceil(sizeData / PER_PAGE);
  // const currentPage = pageNumber * PER_PAGE;
  const displayData = listData
    ?.slice(0, PER_PAGE)
    ?.map((item: any, index: any) => {
      const gameType: any = listGameMode?.find(
        (gameMode: any) => item?.gameModeId == gameMode.gameModeId
      );
      return (
        <tr key={index}>
          <td>{index + PER_PAGE * pageNumber + 1}</td>
          <td className="hidden-name-table">{item?.gameSessionId}</td>
          <td className="hidden-name-table">
            {moment(item?.createdAt).format("DD-MM-yy HH:mm")}
          </td>
          <td className="hidden-name-table">
            {moment(item?.gameCompletedDate).format("DD-MM-yy HH:mm")}
          </td>
          <td className="hidden-name-table">{gameType?.name}</td>
          <td className="hidden-name-table">
            {item?.gameOverData?.map(({ userName }: any, index: number) => (
              <div key={index}>{userName}</div>
            ))}
          </td>
          <td className="hidden-name-table">
            {item?.gameOverData?.map(({ playerId }: any, index: number) => (
              <div key={index}>
                {playerId?.includes("plb_") ? "Bot" : "Player"}
              </div>
            ))}
          </td>
          <td className="hidden-name-table">
            {item?.gameOverData?.map(({ position }: any) => (
              <div key={position}>
                {position}
                {checkOderRank(position)}
              </div>
            ))}
          </td>
          <td className="hidden-name-table">
            {item?.gameOverData?.map(({ totalXp }: any, index: number) => (
              <div key={index}>{totalXp} XP</div>
            ))}
          </td>
          <td className="align-middle">
            <button
              type="button"
              className="btn-primary btn-custom mx-2"
              onClick={() => Navigate("/viewGamePlay/" + item?.gameSessionId)}
            >
              <MdOutlinePreview className="preview-icon" fontSize="large" />
              View
            </button>
            {/* <button type="button" className="btn-custom btn-edit mx-3">
              <AiFillEdit />
              Edit
            </button>

            <button type="button" className="btn-danger btn-custom">
              <MdOutlineDelete className="duplicate-icon" fontSize="large" />
              Remove
            </button> */}
          </td>
        </tr>
      );
    });

  return (
    <Container>
      {false ? (
        <Loading />
      ) : (
        <div className="list-time-bank pb-5">
          <Col>
            <BreadCrumbs childItem="List Game Play" parentItem="Game Play Report" />
            <div className="card m-auto">
              <div className="portlet-title">
                <h3 className="portlet-title__text ">List Game Play</h3>
              </div>
              <div className="search-form mt-5">
                <div className="form d-flex flex-wrap">
                  <div className="d-flex flex-wrap align-items-center w-100 gap-2">
                    <div className="col-12 col-md-2">
                      <input
                        type="text"
                        className="form-control  search"
                        placeholder="Username"
                        name="username"
                        onChange={handelSeachOnChange}
                        value={dataSearch?.username}
                      />
                    </div>
                    {/* <div className="col-12 col-md-2">
                      <select
                        name="sorting"
                        className="form-control"
                        onChange={handelSeachOnChange}
                      >
                        <option hidden>Sorting</option>
                        <option value="createdAt">Created At</option>
                        <option value="totalCoins">Total Coins</option>
                        <option value="playerXpLevel">Player XP Level</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-2">
                      <select
                        name="desc_asc"
                        className="form-control"
                        onChange={handelSeachOnChange}
                      >
                        <option hidden>DESC/ASC</option>
                        <option value="desc">DESC</option>
                        <option value="asc">ASC</option>
                      </select>
                    </div> */}
                    <div className="col-12 col-md-2">
                      <select
                        name="gameType"
                        className="form-control"
                        onChange={handelSeachOnChange}
                      >
                        <option value="">Select GameType</option>
                        {listGameMode.map((obj: any) => {
                          return (
                            <option key={obj._id} value={obj.gameModeId}>
                              {obj.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/*<div className="col-12 col-md-2">
                      <input
                        type="text"
                        className="form-control  search"
                        placeholder="Game Type"
                        name="gameType"
                        onChange={handelSeachOnChange}
                        value={dataSearch?.gameType}
                      />
                      </div>*/}
                    <div className="col-12 col-md-2">
                      <DatePicker
                        showTimeSelect
                        timeIntervals={5}
                        autoComplete="off"
                        dateFormat="dd-MM-yyyy - HH':'mm "
                        placeholderText="Start Date"
                        name="startTime"
                        className="form-control custom-date"
                        selected={startDate}
                        onChange={(e) => handleStartDate(e)}
                      />
                    </div>
                    <div className="col-12 col-md-2">
                      <DatePicker
                        showTimeSelect
                        timeIntervals={5}
                        autoComplete="off"
                        dateFormat="dd-MM-yyyy - HH':'mm "
                        minDate={startDate}
                        name="endTime"
                        placeholderText="End Date"
                        className="form-control custom-date"
                        selected={endDate}
                        onChange={(e) => handleEndDate(e)}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn-green btn-search mt-4 col-6 col-md-2 mx-3"
                      onClick={handleSearch}
                    >
                      <BiSearch />
                      Search
                    </button>
                    {/* <button
                      type="button"
                      className="btn-green btn-reset mt-4 col-6 col-md-3 col-lg-2 mx-3"
                      onClick={handleCSV}
                    >
                      <CSVLink {...csvReport}>
                        <MdOutlinePreview />
                        Export to CSV
                      </CSVLink>
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="table-data overflow-scroll">
                <table className="table table-bordered mt-5">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Game Id</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Game Type</th>
                      <th>Players Username</th>
                      <th>Players Type</th>
                      <th>Players Rank</th>
                      <th>Player XP Gained</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{displayData}</tbody>
                </table>
              </div>
            </div>
          </Col>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            forcePage={pageNumber}
            pageCount={totalPage}
            onPageChange={handlePageChange}
            activeClassName={"page-item active"}
            disabledClassName={"page-item disabled"}
            containerClassName={"pagination"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
          />
          <div className="btn_container">
            <div className="btn_group"></div>
          </div>
        </div>
      )}
    </Container>
  );
};
export default ListGamePlay;
