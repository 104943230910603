import classNames from "classnames/bind";
import { useContext, useState } from "react";
import SidebarItem from "../sidebar-item/SidebarItem";
import styles from "./sidebarData.module.css";
import { IoSettingsOutline } from "react-icons/io5";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineArrowBackIos,
} from "react-icons/md";
interface props {
  id: number;
  name: string;
  children: Array<any>;
  index: number;
}
const cx = classNames.bind(styles);
const SidebarData = ({ id, name, children, index }: props) => {
  const [arrowChange, setArrowChange] = useState(false);
  const [sidebarColor, setSidebarColor] = useState("");
  const [sidebarArrow, setSidebarArrow] = useState<number>();
  const [checkSidebar, setCheckSidebar] = useState("");
  const handleClickMenu = (id: number) => {
    setArrowChange(!arrowChange);
    setCheckSidebar(checkSidebar === "" ? "effect-sidebar-list" : "");
    setSidebarArrow(id);

    setSidebarColor(sidebarColor === "" ? "sidebar-change-color magic" : "");
  };

  return (
    <>
      <li
        className={cx(
          "management",
          "wrapper",
          `${id === sidebarArrow ? sidebarColor : ""}`
        )}
        onClick={() => handleClickMenu(index)}
      >
        <div className={cx("sidebar-icon")}>
          <IoSettingsOutline />
          {name}
        </div>
        <div className={cx("arrow-icon")}>
          {arrowChange ? (
            <MdOutlineKeyboardArrowDown className={cx("arrow-down-icon")} />
          ) : (
            <MdOutlineArrowBackIos className={cx("arrow-back-icon")} />
          )}
        </div>
      </li>
      <ul
        className={cx(
          "sidebar-list",
          `${id === sidebarArrow ? checkSidebar : ""}`
        )}
      >
        {children.map((item: any, index: number) => (
          <div key={index}>
            <SidebarItem
              name={item.name}
              index={index + name}
              destination={item.destination}
            />
          </div>
        ))}
      </ul>
    </>
  );
};

export default SidebarData;
