import { useEffect, useState } from "react";
import { Col, Container, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AiFillEdit } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { MdOutlineDelete, MdOutlinePreview } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import ListPlayersServices from "../../../services/Player.services";
import moment from "moment";
import "./styles.css";
const initSearchData = {
  username: "",
  sorting: "createdAt",
  desc_asc: "desc",
  page: 0,
};
const PER_PAGE = 10;
const ListPlayers = () => {
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [formContent, setFormContent] = useState<any>([]);
  const [listData, setListData] = useState([]);
  const [dataSearch, setDataSearch] = useState(initSearchData);
  const [dataLength, setDataLength] = useState<number>(0);

  //fecthApi
  const fetchApi = (page?: number) => {

    let data = { ...dataSearch, page: page ? page : 0 };
    ListPlayersServices.searchListPlayers(data).then((res: any) => {
      const { total, players } = res;
      setPageNumber(page ? page : 0);
      setDataLength(total);
      setListData(players);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchApi();
  }, []);
  const handlePageChange = ({ selected }: any) => {
    fetchApi(selected);
    setPageNumber(selected);
  };

  // csv
  const handleCSV = () => {
    let content = listData?.map((item: any, index: number) => {
      return {
        "S.No": index,
        Username: item.username,
        "Created At": moment(item?.createdAt).format("DD-MM-yy HH:mm:ss"),
        "Country Name": item.countryName,
        Email: item.emailId,
        "Login Type": item?.isGuest
          ? "Guest"
          : item?.facebookId
            ? "Facebook"
            : "Email",
        "Player Unique Id": item.playerId,
        // "Facebook Id": item.facebookId,
        "Total Coins": item.coins,
        "Player XP Level": item.currentLevel,
        "Total XP": item.playerStats.xpPoints,
        "No. Of Supplies": item.supplies,
      };
    });
    setFormContent(content);
  };
  const csvReport = {
    data: formContent,
    header: [
      "S.No",
      "Username",
      "Created At",
      "Country Name",
      "Email",
      "Login Type",
      "Player Unique Id",
      // "Facebook Id",
      "Total Coins",
      "Player XP Level",
      "Total XP",
      "No. Of Supplies",
    ],
    filename: "ListPlayers.csv",
  };

  //search
  const handleSearch = () => {
    let data = { ...dataSearch, page: 0 };
    ListPlayersServices.searchListPlayers(data).then((res: any) => {
      const { total, players } = res;
      setPageNumber(0);
      setListData(players);
      setDataLength(total);
    });
  };

  const handelSeachOnChange = (e: any) => {
    const { name, value } = e.target;
    let data = { ...dataSearch, [name]: value };
    setDataSearch(data);
  };

  const totalPage = Math.ceil(dataLength / PER_PAGE);
  // const currentPage = pageNumber * PER_PAGE;
  const displayData = listData
    ?.slice(0, PER_PAGE)
    ?.map((item: any, index: any) => {
      return (
        <tr key={index}>
          <td>{index + PER_PAGE * pageNumber + 1}</td>
          <td className="hidden-name-table">{item?.username}</td>
          <td className="hidden-name-table">
            {moment(item?.createdAt).format("DD-MM-yy HH:mm:ss")}
          </td>
          <td className="hidden-name-table">{item?.countryName}</td>
          <td className="hidden-name-table">{item?.emailId}</td>
          <td className="hidden-name-table">
            {item?.isGuest ? "Guest" : item?.facebookId ? "Facebook" : "Email"}
          </td>
          <td className="hidden-name-table">{item?.playerId}</td>
          {/* <td className="hidden-name-table">{item?.facebookId}</td> */}
          <td className="hidden-name-table">{item?.coins}</td>
          <td className="hidden-name-table">{item?.currentLevel}</td>
          <td className="hidden-name-table">{item?.playerStats.xpPoints}</td>
          <td className="hidden-name-table">{item?.supplies}</td>
          <td>
            <button
              type="button"
              className="btn-primary btn-custom mx-2"
              onClick={() =>
                Navigate(
                  "/viewPlayerProfile/" +
                  item?.playerId +
                  "@" +
                  (index + PER_PAGE * pageNumber + 1)
                )
              }
            >
              <MdOutlinePreview className="preview-icon" fontSize="large" />
              View
            </button>
            {/* <button type="button" className="btn-custom btn-edit mx-3">
              <AiFillEdit />
              Edit
            </button>

            <button type="button" className="btn-danger btn-custom">
              <MdOutlineDelete className="duplicate-icon" fontSize="large" />
              Remove
            </button> */}
          </td>
        </tr>
      );
    });

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="list-time-bank pb-5">
          <Col>
            <BreadCrumbs childItem="List Players" parentItem="Player Management" />
            <div className="card m-auto">
              <div className="portlet-title">
                <h3 className="portlet-title__text ">List Players</h3>
              </div>
              <div className="search-form mt-5">
                <div className="form d-flex">
                  <div className="d-flex flex-wrap align-items-center w-100 gap-2">
                    <div className="col-12 col-md-2">
                      <input
                        type="text"
                        className="form-control  search"
                        placeholder="Username"
                        name="username"
                        onChange={handelSeachOnChange}
                        value={dataSearch?.username}
                      />
                    </div>
                    <div className="col-12 col-md-2">
                      <select
                        name="sorting"
                        className="form-control"
                        onChange={handelSeachOnChange}
                      >
                        <option hidden>Sorting</option>
                        <option value="createdAt">Created At</option>
                        <option value="coins">Total Coins</option>
                        <option value="currentLevel">Player XP Level</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-2">
                      <select
                        name="desc_asc"
                        className="form-control"
                        onChange={handelSeachOnChange}
                      >
                        <option hidden>DESC/ASC</option>
                        <option value="desc">DESC</option>
                        <option value="asc">ASC</option>
                      </select>
                    </div>
                    <button
                      type="button"
                      className="btn-green btn-search mt-4 col-6 col-md-2 mx-1"
                      onClick={handleSearch}
                    >
                      <BiSearch />
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn-green btn-search mt-4 col-6 col-md-2 mx-1"
                      onClick={() => {
                        setPageNumber(0);
                        setLoading(true);
                        fetchApi(0);
                      }}
                    >
                      <BiSearch />
                      Reload
                    </button>
                    <button
                      type="button"
                      className="btn-green btn-reset mt-4 col-6 col-md-3 col-lg-2 mx-1"
                      onClick={handleCSV}
                    >
                      <CSVLink {...csvReport}>
                        <MdOutlinePreview />
                        Export to CSV
                      </CSVLink>
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-data overflow-scroll">
                <table className="table table-bordered mt-5">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Username</th>
                      <th>Created At</th>
                      <th>Country Name</th>
                      <th>Email </th>
                      <th>Login Type</th>
                      <th>Player Unique Id</th>
                      {/* <th>Facebook Id</th> */}
                      <th>Total Coins</th>
                      <th>Player XP Level</th>
                      <th>Total XP</th>
                      <th>No. Of Supplies</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{displayData}</tbody>
                </table>
              </div>
            </div>
          </Col>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            forcePage={pageNumber}
            pageCount={totalPage}
            onPageChange={handlePageChange}
            activeClassName={"page-item active"}
            disabledClassName={"page-item disabled"}
            containerClassName={"pagination"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
          />
          <div className="btn_container">
            <div className="btn_group"></div>
          </div>
        </div>
      )}
    </Container>
  );
};
export default ListPlayers;
